import { t } from 'i18next'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PhotoIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import {
    UserSchemaWithoutPassword,
    userSchemaWithoutPassword,
} from 'src/services/api/userApi'
import AuthApi from 'src/services/api/authApi'
import Button from 'src/components/parts/Button'
import Dropdown from 'src/components/parts/Dropdown'
import InputField from 'src/components/parts/InputField'
import { Auth, useAuthContext } from 'src/context/AuthProvider'
import DisplayField from 'src/components/parts/DisplayField'
import { useRoleQuery } from 'src/services/hooks/useRoleQuery'
import { useUserQuery } from 'src/services/hooks/useUserQuery'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import { Role, UserRole, UserRolesEnum } from 'src/services/api/roleApi'

type ProfileDetailsProps = {
    isEditMode: boolean
    setIsEditMode: (value: boolean) => void
}

const ProfileDetails = ({ isEditMode, setIsEditMode }: ProfileDetailsProps) => {
    const { auth, setAuth } = useAuthContext()

    const {
        user: userData,
        updateUser,
        isLoadingUpdateUser,
        isLoadingUser,
    } = useUserQuery({
        userId: auth?.user?.uuid,
    })
    const {
        register,
        setValue,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<UserSchemaWithoutPassword>({
        resolver: zodResolver(userSchemaWithoutPassword),
    })

    const user = useWatch({ control })

    useEffect(() => {
        if (userData) {
            setValue('firstname', userData.firstname)
            setValue('lastname', userData.lastname)
            setValue('email', userData.email)
            setValue('role', userData.role)
        }
    }, [userData, setValue])

    const { roles } = useRoleQuery()
    const filteredRoles = roles?.filter((role) => {
        return role.userRole !== null
    })
    const roleOptions = filteredRoles?.map(({ uuid, userRole }: Role) => {
        return {
            id: uuid,
            label: UserRolesEnum[userRole],
            value: userRole,
        }
    })

    const handleSelectRole = (selectedRole: UserRole) => {
        const findUserRole = (): Role | undefined => {
            return roles?.find(({ userRole }) => userRole === selectedRole)
        }

        const userRole = findUserRole()

        if (userRole) {
            setValue('role', userRole)
        }
    }

    const onSubmit = async (data: UserSchemaWithoutPassword) => {
        try {
            if (auth && auth.user && auth.user.uuid) {
                await updateUser({ userId: auth.user.uuid, ...data })
                toast.success(t('successfully-updated-user', { ns: 'company' }))

                const authUser = await AuthApi.me()
                setAuth((prev: Auth) => ({ ...prev, user: authUser }))

                !isLoadingUpdateUser && setIsEditMode(false)
            }
        } catch (error: any) {
            toast.error(error.message)
        }
    }

    const handleCancel = () => {
        setIsEditMode(false)
    }

    if (isLoadingUser) {
        return <LoadingSpinner />
    }

    return (
        <>
            <div className='flex gap-6'>
                <div className='flex justify-center items-center min-w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'>
                    <PhotoIcon className='w-10 h-10 text-neutral-400' />
                </div>
                {isEditMode ?
                    <>
                        <div className='flex flex-col w-full gap-8'>
                            <div className='flex gap-6'>
                                <InputField
                                    id='firstname'
                                    register={register}
                                    placeholder={t('enter-first-name', {
                                        ns: 'company',
                                    })}
                                    label={t('first-name', {
                                        ns: 'company',
                                    })}
                                    error={errors?.firstname?.message}
                                />
                                <InputField
                                    id='lastname'
                                    register={register}
                                    placeholder={t('enter-last-name', {
                                        ns: 'company',
                                    })}
                                    label={t('last-name', {
                                        ns: 'company',
                                    })}
                                    error={errors?.lastname?.message}
                                />
                            </div>
                            <div className='flex gap-6'>
                                <InputField
                                    id='email'
                                    register={register}
                                    placeholder={t('enter-email-address', {
                                        ns: 'company',
                                    })}
                                    label={t('email-address', {
                                        ns: 'company',
                                    })}
                                    error={errors?.email?.message}
                                />
                                <div className='flex flex-col w-full gap-2'>
                                    <label
                                        htmlFor='role'
                                        className='text-neutral-700 text-sm font-semibold uppercase'
                                    >
                                        {t('role', {
                                            ns: 'company',
                                        })}
                                    </label>
                                    <Dropdown
                                        options={roleOptions || []}
                                        handleSelectedOption={handleSelectRole}
                                        className='!rounded-[4px] bg-white border border-neutral-300'
                                    >
                                        <div className='flex gap-1.5 w-full justify-between'>
                                            <span
                                                className={`text-nowrap ${user?.role?.userRole ?? 'text-neutral-400'} `}
                                            >
                                                {(user?.role?.userRole &&
                                                    UserRolesEnum[
                                                        user?.role.userRole
                                                    ]) ??
                                                    t('select-role', {
                                                        ns: 'company',
                                                    })}
                                            </span>
                                        </div>
                                        <ChevronDownIcon className='h-4 w-4 text-neutral-700' />
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                            <Button
                                onClick={handleCancel}
                                variant='outlined'
                                color='primary-dark'
                                label={t('cancel', { ns: 'company' })}
                            />
                            <Button
                                onClick={handleSubmit(onSubmit)}
                                variant='contained'
                                color='primary-dark'
                                label={t('save', { ns: 'company' })}
                            />
                        </div>
                    </>
                :   <>
                        <div className='flex flex-col w-full gap-8'>
                            <div className='flex gap-6'>
                                <DisplayField
                                    label={t('first-name', {
                                        ns: 'company',
                                    })}
                                    value={user?.firstname}
                                />
                                <DisplayField
                                    label={t('last-name', {
                                        ns: 'company',
                                    })}
                                    value={user?.lastname}
                                />
                            </div>
                            <div className='flex gap-6'>
                                <DisplayField
                                    label={t('email-address', {
                                        ns: 'company',
                                    })}
                                    value={user?.email}
                                />
                                <DisplayField
                                    label={t('role', {
                                        ns: 'company',
                                    })}
                                    value={
                                        user?.role?.userRole &&
                                        UserRolesEnum[user?.role.userRole]
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ProfileDetails
