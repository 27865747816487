import { ReactNode } from 'react'

type IconButtonProps = {
    icon: ReactNode
    onClick: () => void
    id?: string
    label?: string
    className?: string
}

const IconButton = ({
    id,
    icon,
    label,
    className,
    onClick,
}: IconButtonProps) => {
    return (
        <button
            id={id}
            onClick={onClick}
            className={`flex items-center h-9 px-2 py-1 gap-2 rounded-lg cursor-pointer ${className}`}
        >
            {icon}
            {label && <span className='text-sm font-semibold'>{label}</span>}
        </button>
    )
}
export default IconButton
