import {useParams} from 'react-router-dom'

import {useAuthContext} from 'src/context/AuthProvider'
import {t} from "i18next";

type Tabs = {
    id: number
    label: string
    value: 'test-instance' | 'production' | 'versions'
}[]

export const useHooks = () => {
    const {page} = useParams()
    const {company} = useAuthContext()
    const isSupplierCompany = company?.type === 'SUPPLIER'

    const tabs: Tabs = [
        {id: 1, label: t('test-instance', {ns: 'portfolio'}), value: 'test-instance'},
        {
            id: 2,
            label: isSupplierCompany ? t('versions', {ns: 'reference'}) : t('production', {ns: 'reference'}), // TODO translate
            value: isSupplierCompany ? 'versions' : 'production',
        },
    ]

    return {
        page,
        tabs,
    }
}
