
import { z } from 'zod'

import instance from './axiosInstance'
import { User } from './userApi'

export const passwordValidationSchema = z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .regex(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
    .regex(/[a-z]/, 'Password must contain at least 1 lowercase letter')
    .regex(/[0-9]/, 'Password must contain at least 1 number')
    .regex(
        /[!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]/,
        'Password must contain at least 1 symbol',
    )

export const loginSchema = z.object({
    email: z.string(),
    password: z.string(),
    newPassword: z.string().optional(),
})
export const passwordChangeSchema = z.object({
    oldPassword: z.string(),
    newPassword: passwordValidationSchema,
    passwordConfirm: z.string(),
})

export type LoginParams = z.infer<typeof loginSchema>
export type PasswordChangeParams = z.infer<typeof passwordChangeSchema>

const AuthApi = {
    login: (params: LoginParams) => {
        const config = {
            url: '/auth/login',
            method: 'POST',
            data: params,
        }
        return instance.request(config)
    },

    logout: () => {
        const config = {
            url: '/auth/logout',
            method: 'GET',
        }
        return instance.request(config)
    },

    me: async (): Promise<User> => {
        const config = {
            url: 'auth/me',
            method: 'GET',
        }

        try {
            const { data } = await instance.request(config)
            return data
        } catch (error: any) {
            throw new Error(`Failed to fetch logged user: ${error.message}`)
        }
    },

    resetPassword: async ({ email }: { email: string }) => {
        const config = {
            url: '/users/resetpassword',
            method: 'POST',
            data: {
                email,
            },
        }

        try {
            const res = await instance.request(config)
            return res
        } catch (error: any) {
            throw new Error(`Failed to reset password: ${error.message}`)
        }
    },

    changePassword: async (data: PasswordChangeParams) => {
        const { oldPassword, newPassword, passwordConfirm } = data

        const config = {
            url: '/users/changepassword',
            method: 'POST',
            data: {
                old_password: oldPassword,
                new_password: newPassword,
                password_confirmation: passwordConfirm,
            },
        }

        try {
            const { data } = await instance.request(config)
            return data
        } catch (error: any) {
            throw new Error(`Failed to change password: ${error.message}`)
        }
    },
}

export default AuthApi
