export const PillStatusEnum = {
    SUCCESS: 'Success',
    PENDING: 'Pending',
    FAILED: 'Failed',
    FAILURE: 'Failure',
    SCHEDULED: 'Scheduled',
    CANCELED: 'Canceled',
} as const

export const PillChangeStatusEnum = {
    NEW: 'NEW',
    UPDATED: 'UPDATED',
    UNCHANGED: 'UNCHANGED',
    UNAVAILABLE: 'UNAVAILABLE',
}

export type PillStatus = (typeof PillStatusEnum)[keyof typeof PillStatusEnum]
export type PillType =
    (typeof PillChangeStatusEnum)[keyof typeof PillChangeStatusEnum]

export const getPillStatusColor = (status: PillStatus) => {
    switch (status) {
        case PillStatusEnum.SUCCESS:
            return 'bg-primary-green/50'
        case PillStatusEnum.PENDING:
            return 'bg-semantic-warning/50'
        case PillStatusEnum.FAILED:
            return 'bg-primary-red/50'
        case PillStatusEnum.FAILURE:
            return 'bg-primary-red/50'
        case PillStatusEnum.SCHEDULED:
            return 'bg-primary-blue/50'
        case PillStatusEnum.CANCELED:
            return 'bg-primary-red/50'
    }
}

export const getPillChangeStatusColor = (status: PillType) => {
    switch (status) {
        case PillChangeStatusEnum.NEW:
            return 'bg-primary-green/50'
        case PillChangeStatusEnum.UPDATED:
            return 'bg-primary-blue/50'
        case PillChangeStatusEnum.UNAVAILABLE:
            return 'bg-primary-red/50'
        case PillChangeStatusEnum.UNCHANGED:
            return ''
    }
}
