import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

import PortfolioApi from 'src/services/api/portfolioApi'

type PortfolioChangesQueryProps = {
    companyId?: string
    perPage?: number
    page?: number
}

export const usePortfolioChangesQuery = ({
    companyId,
    perPage,
    page
}: PortfolioChangesQueryProps) => {
    const { getPortfolioChanges } = PortfolioApi

    const {
        data: portfolioChangesData,
        isLoading: isLoadingPortfolioChangesData,
        refetch: refetchPortfolioChangesData
    } = useQuery({
        queryKey: ['portfolioChangesData', companyId],
        queryFn: () => (companyId ? getPortfolioChanges({ companyId, page, perPage }) : undefined),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    return {
        portfolioChangesData,
        isLoadingPortfolioChangesData,
        refetchPortfolioChangesData
    }
}
