import {t} from 'i18next'

import Button from 'src/components/parts/Button'
import InputField from 'src/components/parts/InputField'
import {ExclamationCircleIcon} from '@heroicons/react/24/solid'

import {useHooks} from './hooks'

const ProfileChangePassword = () => {
    const {errors, navigate, register, handleSubmit, onSubmit} = useHooks()

    return (
        <div className='flex w-full justify-center'>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className='flex flex-col gap-6 max-w-96'
            >
                <div className='flex flex-col'>
                    <div className='text-neutral-900'>
                        <p>
                            {t('password-must-be', {
                                ns: 'auth',
                            })}
                            :
                        </p>
                        <ul className='ml-4 list-disc'>
                            <li>
                                {t('minimum-8-characters', {
                                    ns: 'auth',
                                })}
                            </li>
                            <li>
                                {t('an-uppercase-character', {
                                    ns: 'auth',
                                })}
                            </li>
                            <li>
                                {t('a-lowercase-character', {
                                    ns: 'auth',
                                })}
                            </li>
                            <li>
                                {t('1-non-letter-character', {
                                    ns: 'auth',
                                })}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col w-96 gap-4'>
                    {errors.newPassword && (
                        <div className='flex items-center w-full rounded-[4px] gap-2 p-2 text-white bg-primary-red'>
                            <ExclamationCircleIcon className='h-4 w-4 text-white'/>
                            <span>{errors?.newPassword?.message}</span>
                        </div>
                    )}
                    <InputField
                        id='oldPassword'
                        register={register}
                        label={t('old-password', {
                            ns: 'auth',
                        })}
                        type='password'
                        placeholder={t('enter-old-password', {
                            ns: 'auth',
                        })}
                    />
                    <InputField
                        id='newPassword'
                        register={register}
                        label={t('new-password', {
                            ns: 'auth',
                        })}
                        type='password'
                        placeholder={t('enter-new-password', {
                            ns: 'auth',
                        })}
                    />
                    <InputField
                        id='passwordConfirm'
                        register={register}
                        label={t('confirm-password', {
                            ns: 'auth',
                        })}
                        type='password'
                        placeholder={t('enter-password-confirmation', {
                            ns: 'auth',
                        })}
                    />
                </div>
            </form>
            <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                <Button
                    onClick={() => navigate('/profile/details')}
                    variant='outlined'
                    color='primary-dark'
                    label={t('cancel', {
                        ns: 'common',
                    })}
                />
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant='contained'
                    color='primary-dark'
                    label={t('save', {
                        ns: 'common',
                    })}
                />
            </div>
        </div>
    )
}

export default ProfileChangePassword
