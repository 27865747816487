// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'article-number': 'Artikelnummer',
    'article-type': 'Artikeltyp',
    active: 'aktiv',
    inactive: 'inaktiv',
    available: 'verfügbar',
    back: 'Zurück',
    availability: 'Verfügbarkeit',
    all: 'Alle',
    browse: 'Durchsuchen',
    'biber-tail': 'Biberschwanz',
    cancel: 'Abbrechen',
    'confirm-publish-reference-data':
        'Sind Sie sicher, dass Sie die Referenzdaten veröffentlichen möchten?',
    'cannot-upload-multiple-files':
        'Mehrere Dateien können nicht hochgeladen werden.',
    'creating-test-instance': 'Testinstanz wird erstellt',
    changes: 'Änderungen',
    deploy: 'Bereitstellen',
    'drag-and-drop': 'Ziehen und ablegen',
    'drop-file-to-upload': 'Datei zum Hochladen ablegen',
    error: 'Fehler',
    'file-to-upload': 'Datei zum Hochladen',
    'import-reference-data': 'Referenzdaten importieren',
    import: 'Importieren',
    'in-progress': 'In Bearbeitung...',
    locked: 'gesperrt',
    'minimize-and-notify': 'Minimieren und benachrichtigen',
    'main-details': 'Hauptdetails',
    manufacturer: 'Hersteller',
    'must-select-file': 'Eine Datei muss ausgewählt werden',
    name: 'Name',
    'overview-of-changes': 'Überblick über die Änderungen',
    'please-wait-while-the-reference-data-is-being-validated':
        'Bitte warten Sie, während die Referenzdaten überprüft werden',
    publish: 'Veröffentlichen',
    'process-notification':
        'Dieser Vorgang kann bis zu 20 Minuten dauern. Wir benachrichtigen Sie so schnell wie möglich.',
    'publish-reference-data': 'Referenzdaten veröffentlichen',
    rollback: 'Zurücksetzen',
    'roll-back-my-changes': 'Meine Änderungen zurücksetzen',
    representative: 'Repräsentant',
    representant: 'Repräsentant',
    rails: 'Schienen',
    'successfully-imported-reference-data':
        'Die Referenzdaten wurden erfolgreich importiert',
    saved: 'Gespeichert',
    status: 'Status',
    'secondary-details': 'Sekundärdetails',
    updated: 'Aktualisiert',
    test: 'Test',
    'test-portfolio': 'Testportfolio',
    upload: 'Hochladen',
    unavailable: 'nicht verfügbar',
    'value-from': 'Wert von',
    'view-details': 'Details anzeigen',
    validating: 'Validierung',
    value: 'Wert',
    versions: "Versionen",
    publishDate: 'Veröffentlicht am',
    'create-test-instance': 'Testinstanz erzeugen',
}
