import axios from 'axios'
import Cookies from 'universal-cookie'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

const cookies = new Cookies()
const TOKEN = cookies.get('token')

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

if (TOKEN) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
}

const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [isSet, setIsSet] = useState(false)

    useEffect(() => {
        setIsSet(true)
        const responseInterceptor = (response: any) => {
            return response
        }

        const errorInterceptor = (error: any) => {
            if (
                (error.response.status === 401 ||
                    error.response.status === 403) &&
                pathname !== '/login'
            ) {
                cookies.remove('token')
                toast.error('Authentication failed. Please login.')
                navigate('/login', { state: { from: pathname }, replace: true })
            }

            return Promise.reject(error)
        }

        const interceptor = instance.interceptors.response.use(
            responseInterceptor,
            errorInterceptor,
        )

        return () => instance.interceptors.response.eject(interceptor)
    }, [pathname, navigate])

    return isSet && children
}

const setBearerToken = (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default instance
export { AxiosInterceptor, setBearerToken }
