import { z } from 'zod'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import AuthApi from 'src/services/api/authApi'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'

const initialFormState = {
    email: '',
}

export const resetPasswordSchema = z.object({
    email: z
        .string()
        .email()
        .trim()
        .min(1, { message: 'Email address is required' }),
})

export type ResetPasswordParams = z.infer<typeof resetPasswordSchema>

export const useHooks = () => {
    const navigate = useNavigate()
    const { resetPassword } = AuthApi
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ResetPasswordParams>({
        resolver: zodResolver(resetPasswordSchema),
    })

    const onSubmit = async ({ email }: ResetPasswordParams) => {
        try {
            await resetPassword({ email })

            reset(initialFormState)
            setPasswordResetSuccess(true)
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    return {
        errors,
        passwordResetSuccess,
        navigate,
        register,
        onSubmit,
        handleSubmit,
    }
}
