import { t } from 'i18next'

type ValidationError = {
    type: string
    message: string
    'artikel-type': string
    Artikelnummer?: string
}
type ValidationErrorsTableProps = {
    validationErrors: ValidationError[]
}

const ValidationErrorsTable = ({
    validationErrors,
}: ValidationErrorsTableProps) => {
    return (
        <table className='w-full text-sm text-left'>
            <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                <tr>
                    <th
                        scope='col'
                        className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('article-number', { ns: 'portfolio' })}
                    </th>
                    <th
                        scope='col'
                        className='w-60 px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('name', { ns: 'portfolio' })}
                    </th>
                    <th
                        scope='col'
                        className='px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('error', {
                            ns: 'portfolio',
                        })}
                    </th>
                </tr>
            </thead>
            <tbody>
                {validationErrors.map(
                    (
                        { Artikelnummer, 'artikel-type': artikelType, message },
                        index,
                    ) => {
                        return (
                            <tr
                                key={index}
                                className='bg-neutral-50 border-neutral-200 border-b-[1px] hover:bg-white'
                            >
                                <td className='px-8 py-4 whitespace-nowrap'>
                                    {Artikelnummer}
                                </td>
                                <td className='px-8 py-4'>
                                    <div className='flex items-center space-x-2 whitespace-nowrap'>
                                        {artikelType}
                                    </div>
                                </td>
                                <td className='px-8 py-5 overflow-hidden whitespace-nowrap overflow-ellipsis '>
                                    {message}
                                </td>
                            </tr>
                        )
                    },
                )}
            </tbody>
        </table>
    )
}
export default ValidationErrorsTable
