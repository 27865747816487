import { Toaster } from 'react-hot-toast'

const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className='min-h-screen flex flex-col'>
            <Toaster position='top-right' />
            {children}
        </div>
    )
}

export default Layout
