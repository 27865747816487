import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import CompanyApi from 'src/services/api/companyApi'

type CompanyQueryProps = {
    companyId?: string
}

export const useCompanyQuery = ({ companyId }: CompanyQueryProps) => {
    const queryClient = useQueryClient()

    const { create, get, getAll, update } = CompanyApi

    const { data: company, isLoading: isLoadingCompany } = useQuery({
        queryKey: 'company',
        queryFn: () => (companyId ? get({ companyId }) : undefined),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    const { data: companyData, isLoading: isLoadingCompanies } = useQuery({
        queryKey: 'companies',
        queryFn: () => getAll({perPage: 100}),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    const {
        mutateAsync: createCompany,
        isError: isCreateCompanyError,
    } = useMutation(create)

    const {
        mutateAsync: updateCompany,
        isLoading: isLoadingUpdateCompany,
        isError: isUpdateCompanyError,
    } = useMutation(update, {
        onSuccess: () => {
            queryClient.invalidateQueries('company')
            queryClient.invalidateQueries('companies')
            toast.success('Successfully updated company')
        },
    })

    return {
        company,
        companyData,
        isLoadingCompany,
        isLoadingCompanies,
        isLoadingUpdateCompany,
        isUpdateCompanyError,
        isCreateCompanyError,
        createCompany,
        updateCompany,
    }
}
