import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

import DeploymentApi from 'src/services/api/deploymentApi'

type TestDeploymentQueryProps = {
    companyId?: string
}

export const useTestDeploymentQuery = ({
    companyId,
}: TestDeploymentQueryProps) => {
    const { getAllTestDeployments } = DeploymentApi

    const { data: testDeployments, isLoading: isLoadingTestDeployments } =
        useQuery({
            queryKey: 'testDeployments',
            queryFn: () =>
                companyId ?
                    getAllTestDeployments({ companyId })
                :   undefined,
            onError: (error: any) => {
                toast.error(error.message)
            },
            retry: 2,
        })

    return {
        testDeployments,
        isLoadingTestDeployments,
    }
}
