type PillProps = {
    label?: string | number
    className?: string
}

const Pill = ({ label, className }: PillProps) => {
    if (!label) return

    return (
        <span
            className={`flex justify-center items-center h-5 rounded-full py-[1px] px-[10px] font-semibold text-xs uppercase ${className}`}
        >
            {label}
        </span>
    )
}
export default Pill
