import { useState } from 'react'
import toast from 'react-hot-toast'
import { QueryClient } from 'react-query'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useParams } from 'react-router-dom'

import { useUserQuery } from 'src/services/hooks/useUserQuery'
import { useRoleQuery } from 'src/services/hooks/useRoleQuery'
import { useCompanyQuery } from 'src/services/hooks/useCompanyQuery'
import { Role, UserRole, UserRolesEnum } from 'src/services/api/roleApi'
import UserApi, { UserSchema, userSchema } from 'src/services/api/userApi'

const STATUS = 'ACTIVE'

export const useHooks = () => {
    const queryClient = new QueryClient()
    const navigate = useNavigate()
    const { roles } = useRoleQuery()
    const { companyId } = useParams()
    const { createUser } = useUserQuery({})
    const { company, isLoadingCompany } = useCompanyQuery({ companyId })
    const { get } = UserApi

    const [isLoadingCreateUser, setIsLoadingCreateUser] = useState(false)

    const filteredRoles = roles?.filter((role) => {
        return role.userRole !== null
    })
    const roleOptions = filteredRoles?.map(({ uuid, userRole }: Role) => {
        return {
            id: uuid,
            label: UserRolesEnum[userRole],
            value: userRole,
        }
    })

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<UserSchema>({
        resolver: zodResolver(userSchema),
    })

    const { role } = useWatch({ control })

    const [selectedRole, setSelectedRole] = useState<UserRole | null>(null)
    const [isShowConfirmationModal, setIsShowConfirmationModal] =
        useState<boolean>(false)

    const handleSelectRole = (selectedRole: UserRole) => {
        setSelectedRole(selectedRole)
        const userRole = roles?.find(
            ({ userRole }) => userRole === selectedRole,
        )

        if (userRole) {
            setValue('role', userRole)
        }
    }

    const handleAddUser = () => {
        setIsShowConfirmationModal(true)
    }
    const handleBack = () => {
        navigate(-1)
    }
    const handleCancel = () => {
        setIsShowConfirmationModal(false)
    }

    const onSubmit = async (data: UserSchema) => {
        setIsLoadingCreateUser(true)
        try {
            if (companyId) {
                const res = await createUser({
                    ...data,
                    organizationId: companyId,
                    status: STATUS,
                })

                fetchNewUser(res.uuid)
            }
        } catch (error: any) {
            toast.error(error.message)
        }

        setIsShowConfirmationModal(false)
    }

    const fetchNewUser = async (userId: string) => {
        try {
            await get({ userId })
            toast.success('Successfully created a new user!')
            queryClient.invalidateQueries('companyUsers')

            setIsLoadingCreateUser(false)
            navigate(-1)
        } catch (error) {
            setTimeout(() => fetchNewUser(userId), 2000)
        }
    }

    return {
        role,
        errors,
        company,
        roleOptions,
        selectedRole,
        isLoadingCompany,
        isLoadingCreateUser,
        isShowConfirmationModal,
        register,
        onSubmit,
        handleBack,
        handleSubmit,
        handleCancel,
        handleAddUser,
        handleSelectRole,
    }
}
