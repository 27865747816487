import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import UserApi from 'src/services/api/userApi'

type UserQueryProps = {
    userId?: string
    companyId?: string
    perPage?: number
    page?: number
}

export const useUserQuery = ({ userId, companyId, perPage, page }: UserQueryProps) => {
    const queryClient = useQueryClient()

    const { create, get, getAllByCompany, update } = UserApi

    const { data: user, isLoading: isLoadingUser } = useQuery({
        queryKey: 'user',
        queryFn: () => (userId ? get({ userId }) : undefined),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    const { data: usersByCompanyData, isLoading: isLoadingUsersByCompanyData, refetch: refetchUsersByCompanyData } =
        useQuery({
            queryKey: ['companyUsers', companyId],
            queryFn: () =>
                companyId ? getAllByCompany({ companyId, perPage, page }) : undefined,
        })

    const { mutateAsync: createUser, isError: isCreateUserError } =
        useMutation(create)

    const {
        mutateAsync: updateUser,
        isLoading: isLoadingUpdateUser,
        isError: isUpdateUserError,
    } = useMutation(update, {
        onSuccess: () => {
            queryClient.invalidateQueries('users')
            queryClient.invalidateQueries('companyUsers')
        },
    })

    return {
        user,
        usersByCompanyData,
        isLoadingUser,
        isLoadingUpdateUser,
        isLoadingUsersByCompanyData,
        isCreateUserError,
        isUpdateUserError,
        createUser,
        updateUser,
        refetchUsersByCompanyData
    }
}
