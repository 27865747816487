import { useState } from 'react'

export const useHooks = () => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const handleEditModeToggle = () => {
        setIsEditMode(!isEditMode)
    }

    return {
        isEditMode,
        setIsEditMode,
        handleEditModeToggle,
    }
}
