import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import Logo from 'src/components/parts/Logo'
import Button from 'src/components/parts/Button'
import InputField from 'src/components/parts/InputField'
import InputCheckbox from 'src/components/parts/InputCheckbox'

import { useHooks } from './hooks'

const Login = () => {
    const { error, register, handleSubmit, onSubmit } = useHooks()

    return (
        <div className='flex h-screen'>
            <Logo className='absolute top-4 left-4' />
            <div className='flex justify-center items-center w-1/2 min-w-96 h-full '>
                <div className='flex flex-col min-w-80 max-w-96 gap-8'>
                    <div className='flex flex-col items-center space-y-[10px] text-center'>
                        <h1 className='text-2xl font-bold text-primary-dark '>
                            {t('welcome-back-to-mms', { ns: 'auth' })}
                        </h1>
                        <p className='text-neutral-900'>
                            {t('please-enter-your-details-to-login', {
                                ns: 'auth',
                            })}
                        </p>
                    </div>
                    {error && (
                        <div className='flex items-center w-full rounded-[4px] gap-2 p-2 text-white bg-primary-red'>
                            <ExclamationCircleIcon className='h-4 w-4 text-white' />
                            <span>{t('login-failed', { ns: 'auth' })}</span>
                        </div>
                    )}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='flex flex-col w-full gap-5'
                    >
                        <div className='flex flex-col gap-4'>
                            <InputField
                                id='email'
                                register={register}
                                label={t('email-address', { ns: 'auth' })}
                                type='text'
                                placeholder={t('enter-email-address', {
                                    ns: 'auth',
                                })}
                            />
                            <InputField
                                id='password'
                                register={register}
                                label={t('password', { ns: 'auth' })}
                                type='password'
                                placeholder={t('enter-password', {
                                    ns: 'auth',
                                })}
                            />
                        </div>
                        <div className='flex justify-between'>
{/*                            <InputCheckbox
                                label={t('remember-me', { ns: 'auth' })}
                            />*/}
                            <Link
                                to='/forgot-password'
                                className='text-sm font-semibold text-primary-green'
                            >
                                {t('forgot-password', { ns: 'auth' })}
                            </Link>
                        </div>
                        <Button
                            variant='contained'
                            color='primary-green'
                            className='mt-4'
                            label={t('log-in', { ns: 'auth' })}
                        />
                    </form>
                </div>
            </div>
            <div className='flex w-1/2 h-full bg-primary-green' />
        </div>
    )
}

export default Login
