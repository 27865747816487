import {t} from 'i18next'
import {ExclamationCircleIcon} from '@heroicons/react/24/solid'

import Logo from 'src/components/parts/Logo'
import Button from 'src/components/parts/Button'
import InputField from 'src/components/parts/InputField'

import {useHooks} from './hooks'

const PasswordSetup = () => {
    const {
        errors,
        passwordSetupSuccess,
        register,
        navigate,
        onSubmit,
        handleSubmit,
    } = useHooks()

    const PasswordSetupSuccessMessage = () => {
        return (
            <>
                <div className='flex flex-col gap-2 items-center w-full'>
                    <span className='font-bold text-2xl'>Success</span>
                    <div className='text-neutral-900'>
                        <span>
                            {t('password-reset-successful', {ns: 'auth'})}
                        </span>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <Button
                        onClick={() => navigate('/')}
                        variant='contained'
                        color='primary-dark'
                        label={t('log-in', {ns: 'auth'})}
                    />
                </div>
            </>
        )
    }

    return (
        <div className='flex flex-col min-h-screen w-full p-10 items-center gap-12 bg-surface text-primary-dark'>
            <Logo/>
            <div
                className='flex flex-col justify-center items-start min-w-[500px] p-10 gap-12 border border-neutral-400 bg-neutral-200 rounded-lg'>
                {passwordSetupSuccess ?
                    <PasswordSetupSuccessMessage/>
                    : <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='flex flex-col w-full gap-12'
                    >
                        <div className='flex flex-col gap-2'>
                            <span className='font-bold text-2xl'>
                                {t('set-new-password', {ns: 'auth'})}
                            </span>
                            <div className='text-neutral-900'>
                                <p>
                                    {t('password-must-be', {
                                        ns: 'auth',
                                    })}
                                    :
                                </p>
                                <ul className='ml-4 list-disc'>
                                    <li>
                                        {t('minimum-8-characters', {
                                            ns: 'auth',
                                        })}
                                    </li>
                                    <li>
                                        {t('an-uppercase-character', {
                                            ns: 'auth',
                                        })}
                                    </li>
                                    <li>
                                        {t('a-lowercase-character', {
                                            ns: 'auth',
                                        })}
                                    </li>
                                    <li>
                                        {t('1-non-letter-character', {
                                            ns: 'auth',
                                        })}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            {errors.newPassword && (
                                <div
                                    className='flex items-center w-full rounded-[4px] gap-2 p-2 text-white bg-primary-red'>
                                    <ExclamationCircleIcon className='h-4 w-4 text-white'/>
                                    <span>{errors.newPassword.message}</span>
                                </div>
                            )}
                            <InputField
                                id='newPassword'
                                register={register}
                                label={t('new-password', {
                                    ns: 'auth',
                                })}
                                type='password'
                                placeholder={t('enter-new-password', {
                                    ns: 'auth',
                                })}
                            />
                            <InputField
                                id='passwordConfirm'
                                register={register}
                                label={t('confirm-password', {
                                    ns: 'auth',
                                })}
                                type='password'
                                placeholder={t('enter-password-confirmation', {
                                    ns: 'auth',
                                })}
                            />
                        </div>
                        <Button
                            variant='contained'
                            color='primary-dark'
                            label={t('confirm', {ns: 'auth'})}
                        />
                    </form>
                }
            </div>
        </div>
    )
}

export default PasswordSetup
