import toast from 'react-hot-toast'
import {useQuery} from 'react-query'

import PortfolioApi from 'src/services/api/portfolioApi'

type PortfolioQueryProps = {
    companyId?: string
    perPage?: number
    page?: number,
    type?: string
}

export const usePortfolioQuery = ({
                                      companyId,
                                      perPage,
                                      page,
                                      type
                                  }: PortfolioQueryProps) => {
    const {getAll} = PortfolioApi
    const {
        data: portfolioData,
        isLoading: isLoadingPortfolioData,
        refetch: refetchPortfolioData,
    } = useQuery({
        queryKey: ['portfolioData', companyId],
        queryFn: () =>
            companyId ? getAll({companyId, perPage, page, type}) : undefined,
        onError: (error: any) => {
            toast.error(error.message)
        },
        onSettled: (data: any) => {
            document.body.classList.remove('loading')
        },
        retry: 2,
    })

    return {
        portfolioData,
        isLoadingPortfolioData,
        refetchPortfolioData,
    }
}
