import { t } from 'i18next'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthApi } from 'src/services/hooks/useAuthApi'
import {
    PasswordChangeParams,
    passwordChangeSchema,
} from 'src/services/api/authApi'

export const useHooks = () => {
    const navigate = useNavigate()
    const { changePassword } = useAuthApi()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm<PasswordChangeParams>({
        resolver: zodResolver(passwordChangeSchema),
    })

    const onSubmit = async ({
        oldPassword,
        newPassword,
        passwordConfirm,
    }: PasswordChangeParams) => {
        if (newPassword !== passwordConfirm) {
            return setError('newPassword', {
                message: `${t('passwords-do-not-match', {
                    ns: 'common',
                })} `,
            })
        }

        try {
            await changePassword({
                oldPassword,
                newPassword,
                passwordConfirm,
            })

            reset()
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    return {
        errors,
        navigate,
        register,
        handleSubmit,
        onSubmit,
    }
}
