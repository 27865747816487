import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthApi } from 'src/services/hooks/useAuthApi'
import { LoginParams, loginSchema } from 'src/services/api/authApi'

const initialFormState = {
    email: '',
    password: '',
}

export const useHooks = () => {
    const { login } = useAuthApi()

    const [error, setError] = useState<string | null>(null)

    const { register, handleSubmit, reset } = useForm<LoginParams>({
        resolver: zodResolver(loginSchema),
    })

    const onSubmit = async (params: LoginParams) => {
        try {
            await login(params)
            reset(initialFormState)
        } catch (error: any) {
            setError(error.message)
        }
    }

    return {
        error,
        register,
        handleSubmit,
        onSubmit,
    }
}
