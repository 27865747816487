import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { en, de } from './dict'

const resources = {
    en,
    de,
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        resources,
        /**
         * To add another namespace, simply create a new file and name it after the namespace, then export it in `src/lib/dict/de/index.ts`.
         * Please see `src/lib/dict/de/comon.ts` as an example on how the namespace is structured
         *
         * i.e.
         * export  { default as sample } from './sample'
         *
         * To use the namespace, you may add it as the second parameter in the useTranslation i18next function:
         *
         * i.e.
         * {t('enter-your-login-details', {ns: 'common'})}
         */
        ns: ['common', 'auth', 'company', 'deployment', 'portfolio'],
        defaultNS: 'common',
        debug: true,
        supportedLngs: ['en', 'de'],
        interpolation: {
            escapeValue: false,
        },
    })
