import { t } from 'i18next'
import {
    ArrowUpOnSquareIcon,
    DocumentIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid'

import Button from 'src/components/parts/Button'
import BackButton from 'src/components/parts/BackButton'
import LoadingCard from 'src/components/parts/LoadingCard'
import ValidationErrorsTable from 'src/components/templates/ValidationErrorsTable'

import { useHooks } from './hooks'

const fileAcceptTypes = ['png', 'xls', 'xlsx']

const ImportReferenceData = () => {
    const {
        file,
        isOver,
        isValidating,
        validationErrors,
        handleBack,
        handleDrop,
        handleImport,
        handleDragOver,
        handleDragLeave,
        handleFileChange,
        handleRemoveFile,
        handleCloseModal,
    } = useHooks()

    return (
        <>
            <div className='flex flex-col w-full gap-4 text-primary-dark'>
                <div className='flex items-center gap-4'>
                    <BackButton href='/portfolio' />
                    <span className='font-bold text-2xl'>
                        {t('import-reference-data', { ns: 'portfolio' })}
                    </span>
                </div>
                <div
                    className='flex flex-col gap-4 mx-10'
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div
                        id='drag-and-drop-field'
                        className={`flex justify-center items-center w-full h-36 p-8 border-2  rounded-[4px] ${isOver ? 'border-solid border-netural-700 bg-white' : 'border-dashed border-neutral-400 bg-neutral-50'}`}
                    >
                        <div className='flex flex-col items-center gap-2 text-neutral-700'>
                            <ArrowUpOnSquareIcon
                                className={`h-6 w-6 ${isOver && 'animate-bounce-fast'}`}
                            />
                            {isOver ?
                                <span>
                                    {t('drop-file-to-upload', {
                                        ns: 'portfolio',
                                    })}
                                </span>
                            :   <div>
                                    <span>
                                        {t('drag-and-drop', {
                                            ns: 'portfolio',
                                        })}{' '}
                                        or{' '}
                                    </span>
                                    <label
                                        htmlFor='file-upload'
                                        className='underline cursor-pointer'
                                    >
                                        {t('browse', { ns: 'portfolio' })}{' '}
                                        <input
                                            id='file-upload'
                                            type='file'
                                            className='hidden'
                                            accept={fileAcceptTypes.join(', ')}
                                            onClick={(e: any) =>
                                                (e.target.value = null)
                                            }
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                    <span>
                                        {t('file-to-upload', {
                                            ns: 'portfolio',
                                        })}
                                        .
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    {file && (
                        <div className='flex w-full p-4 justify-between items-center bg-white rounded-[4px]'>
                            <div className='flex items-center gap-2'>
                                <DocumentIcon className='h-6 w-6' />
                                <div className='flex flex-col'>
                                    <span>{file.name}</span>
                                    <span className='text-xs text-neutral-500'>
                                        {file.size / 1000} KB
                                    </span>
                                </div>
                            </div>
                            <Button
                                label={<XMarkIcon className='h-6 w-6' />}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </div>
                <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                    <Button
                        onClick={handleBack}
                        variant='outlined'
                        color='primary-dark'
                        label={t('cancel', { ns: 'portfolio' })}
                    />
                    <Button
                        onClick={handleImport}
                        variant='contained'
                        color='primary-dark'
                        label={t('import', { ns: 'portfolio' })}
                        disabled={!file}
                    />
                </div>
            </div>
            {isValidating && (
                <LoadingCard
                    label={t('validating', { ns: 'portfolio' })}
                    children={
                        <span className='text-center'>
                            {t(
                                'please-wait-while-the-reference-data-is-being-validated',
                                { ns: 'portfolio' },
                            )}
                            ..
                        </span>
                    }
                />
            )}
            {validationErrors && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50'>
                    <div className='absolute flex flex-col items-center max-h-[640px] gap-6 p-5 rounded-lg bg-white overflow-hidden z-50'>
                        <div className='flex justify-between items-start w-full'>
                            <div className='flex flex-col gap-2'>
                                <span className='text-xl font-bold text-primary-red uppercase'>
                                    Reference data import failed
                                </span>
                                <span className='text-base'>
                                    We found following fields to be invalid.
                                    Please correct them accordingly and import
                                    again.
                                </span>
                            </div>
                            <button onClick={handleCloseModal}>
                                <XMarkIcon className='h-6 w-6' />
                            </button>
                        </div>

                        <div className='h-[960px] overflow-auto'>
                            <ValidationErrorsTable
                                validationErrors={validationErrors}
                            />
                        </div>
                        <div className='flex w-full justify-end'>
                            <Button
                                onClick={handleCloseModal}
                                variant='contained'
                                color='primary-dark'
                                label='OK'
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ImportReferenceData
