import { t } from 'i18next'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/parts/Button'
import Dropdown from 'src/components/parts/Dropdown'
import InputField from 'src/components/parts/InputField'
import BackButton from 'src/components/parts/BackButton'
import { UserRolesEnum } from 'src/services/api/roleApi'
import DisplayField from 'src/components/parts/DisplayField'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'

import { useHooks } from './hooks'

const UserDetail = () => {
    const {
        user,
        errors,
        isEditMode,
        roleOptions,
        isLoadingUser,
        register,
        handleSubmit,
        handleSelectRole,
        handleSaveChanges,
        handleEditModeToggle,
    } = useHooks()

    if (isLoadingUser) {
        return <LoadingSpinner />
    }

    return (
        <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
                <div className='flex items-center gap-4 text-primary-dark'>
                    <BackButton />
                    <span className='font-bold text-2xl'>
                        {t('user-info', { ns: 'company' })}
                    </span>
                </div>
                <div className='mr-10'>
                    <Button
                        onClick={
                            isEditMode ?
                                handleSubmit(handleSaveChanges)
                            :   handleEditModeToggle
                        }
                        variant='contained'
                        color='primary-dark'
                        label={
                            isEditMode ?
                                t('save-changes', { ns: 'company' })
                            :   t('edit-details', { ns: 'company' })
                        }
                    />
                </div>
            </div>
            <div className='flex mx-10 space-x-8'>
                <div className='flex flex-col w-full gap-10'>
                    {isEditMode ?
                        <>
                            <div className='flex space-x-8'>
                                <InputField
                                    id='firstname'
                                    register={register}
                                    placeholder={t('enter-first-name', {
                                        ns: 'company',
                                    })}
                                    label={t('first-name', { ns: 'company' })}
                                    error={errors?.firstname?.message}
                                />
                                <InputField
                                    id='lastname'
                                    register={register}
                                    placeholder={t('enter-last-name', {
                                        ns: 'company',
                                    })}
                                    label={t('last-name', { ns: 'company' })}
                                    error={errors?.lastname?.message}
                                />
                            </div>
                            <div className='flex space-x-8'>
                                <InputField
                                    id='email'
                                    register={register}
                                    placeholder={t('enter-email-address', {
                                        ns: 'company',
                                    })}
                                    label={t('email-address', {
                                        ns: 'company',
                                    })}
                                    error={errors?.email?.message}
                                />
                                <div className='flex flex-col w-full gap-2'>
                                    <label
                                        htmlFor='role'
                                        className='text-neutral-700 text-sm font-semibold uppercase'
                                    >
                                        {t('role', {
                                            ns: 'company',
                                        })}
                                    </label>
                                    <Dropdown
                                        options={roleOptions || []}
                                        handleSelectedOption={handleSelectRole}
                                        className='!rounded-[4px] bg-white border border-neutral-300'
                                    >
                                        <div className='flex gap-1.5 w-full justify-between'>
                                            <span
                                                className={`text-nowrap ${user?.role?.userRole ?? 'text-neutral-400'} `}
                                            >
                                                {(user?.role?.userRole &&
                                                    UserRolesEnum[
                                                        user?.role.userRole
                                                    ]) ??
                                                    t('select-role', {
                                                        ns: 'company',
                                                    })}
                                            </span>
                                        </div>
                                        <ChevronDownIcon className='h-4 w-4 text-neutral-700' />
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    :   <>
                            <div className='flex space-x-8'>
                                <DisplayField
                                    label={t('first-name', {
                                        ns: 'company',
                                    })}
                                    value={user?.firstname}
                                />
                                <DisplayField
                                    label={t('last-name', {
                                        ns: 'company',
                                    })}
                                    value={user?.lastname}
                                />
                            </div>
                            <div className='flex space-x-8'>
                                <DisplayField
                                    label={t('email-address', {
                                        ns: 'company',
                                    })}
                                    value={user?.email}
                                />
                                <DisplayField
                                    label={t('role', {
                                        ns: 'company',
                                    })}
                                    value={
                                        user?.role?.userRole &&
                                        UserRolesEnum[user?.role.userRole]
                                    }
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserDetail
