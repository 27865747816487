import {t} from 'i18next'
import {PhotoIcon} from '@heroicons/react/24/solid'

import Modal from 'src/components/parts/Modal'
import Button from 'src/components/parts/Button'
import InputField from 'src/components/parts/InputField'
import BackButton from 'src/components/parts/BackButton'
import LoadingCard from 'src/components/parts/LoadingCard'

import {useHooks} from './hooks'
import {toTitleCase} from "../../../utils/toTitleCase.ts";

const CreateCompany = () => {
    const {
        type,
        errors,
        categories,
        isShowModal,
        companyImage,
        isLoadingCreateCompany,
        onSubmit,
        register,
        unregister,
        handleBack,
        handleSubmit,
        handleCancel,
        handleAddCompany,
    } = useHooks()

    if (isLoadingCreateCompany) {
        return (
            <LoadingCard
                label={t('creating-new-company', {ns: 'company'})}
                children={
                    <div className='flex flex-col justify-center space-y-2'>
                        <span className='text-center'>
                            {t('initializing-portfolio-data', {
                                ns: 'company',
                            })}
                        </span>
                    </div>
                }
            />
        )
    }

    function getProductionUrlField() {
        if (type !== 'DISTRIBUTOR') {
            return <></>
        } else {
            return <div>
                <div className='flex flex-col w-full gap-2'>
                    <label
                        htmlFor={"productionInstanceUrl"}
                        className={`text-neutral-700 text-sm font-semibold uppercase ${type !== 'DISTRIBUTOR' && 'text-disabled'}`}
                    >
                        {t('production-instance-url', {
                            ns: 'company',
                        })?.toUpperCase()}
                        {errors.productionInstanceUrl &&
                            <span
                                className='text-primary-red text-sm'> * for example https://www.solar-planit.de/</span>}
                    </label>
                    <div className='relative'>
                        <input
                            id={"productionInstanceUrl"}
                            {...register('productionInstanceUrl', {
                                required: type !== 'DISTRIBUTOR',
                                minLength: 10,
                                pattern:
                                    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                            })}
                            placeholder={t('paste-production-instance-url', {
                                ns: 'company',
                            })}
                            className={`relative h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px] checked:bg-primary-green checked:text-white placeholder:font-light placeholder:text-neutral-400 ${type !== 'DISTRIBUTOR' && 'placeholder:text-disabled'}`}
                            disabled={type !== 'DISTRIBUTOR'}
                        />
                    </div>
                </div>
            </div>;
        }
    }

    return (
        <div className='flex flex-col gap-6'>
            <div className='flex items-center gap-4 text-primary-dark'>
                <BackButton href='/company'/>
                <span className='font-bold text-2xl'>
                    {t('add-new-company', {ns: 'company'})}
                </span>
            </div>
            <div className='flex mx-8 space-x-8'>
                <div className='flex flex-col space-y-[10px]'>
                    {companyImage ?
                        <img
                            src=''
                            alt='new-company'
                            className='flex w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'
                        />
                        : <div
                            className='flex justify-center items-center w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'>
                            <PhotoIcon className='w-10 h-10 text-neutral-400'/>
                        </div>
                    }
                    {/*
                    <span className='font-semibold text-sm text-neutral-700'>
                        {t('add-image', { ns: 'company' }).toUpperCase()}
                    </span>
                    */}
                </div>
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex flex-col gap-2 font-semibold text-sm text-neutral-700'>
                        <span>
                            {t('category', {ns: 'company'}).toUpperCase()}
                            {errors?.type?.message && (
                                <span className='text-primary-red text-sm'>
                                    {' '}
                                    *
                                </span>
                            )}
                        </span>
                        <div className='flex gap-5'>
                            {categories.map(({id, label, value}) => {
                                return (
                                    <div className='flex space-x-2'>
                                        <input
                                            type='radio'
                                            id={id + ""}
                                            {...register("type", {
                                                onChange: (_) => unregister("productionInstanceUrl")
                                            })}
                                            value={value}
                                        />
                                        <label htmlFor={id + ""} className={"font-semibold text-sm text-neutral-700"}>
                                            {toTitleCase(label)}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='flex max-w-96'>
                        <InputField
                            id='name'
                            register={register}
                            placeholder={t('enter-name', {ns: 'company'})}
                            label={t('name')}
                            error={errors?.name?.message}
                        />
                    </div>
                    {getProductionUrlField()}
                </div>
            </div>
            <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                <Button
                    onClick={handleBack}
                    variant='outlined'
                    color='primary-dark'
                    label={t('cancel', {ns: 'company'})}
                />
                <Button
                    onClick={handleSubmit(handleAddCompany)}
                    variant='contained'
                    color='primary-dark'
                    label={t('add-new-company', {ns: 'company'})}
                />
            </div>
            {isShowModal && (
                <Modal
                    label={t('add-new-company', {ns: 'company'})}
                    content={t('confirm-add-new-company', {ns: 'company'})}
                    handleCancel={handleCancel}
                    handleConfirm={handleSubmit(onSubmit)}
                />
            )}
        </div>
    )
}
export default CreateCompany
