import {Link} from 'react-router-dom'

import {useHooks} from './hooks'
import {ReferenceVersionsTable} from './reference-versions'
import {ProductionDeploymentsTable} from './prod-deployments'
import {TestDeploymentsTable} from './test-deployments'

const DeploymentPage = () => {
    const {page, tabs} = useHooks()

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex w-full justify-between'>
                <div className='flex gap-6'>
                    {tabs.map(({id, label, value}) => {
                        return (
                            <Link
                                to={`/deployment/${value}`}
                                key={id}
                                className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer 
                                ${page === value && 'text-primary-dark border-b-2 border-primary-dark'}
                                `}
                            >
                                {label}
                            </Link>
                        )
                    })}
                </div>
            </div>

            {page === 'test-instance' ?
                <TestDeploymentsTable/>
                : page === 'production' ?
                    <ProductionDeploymentsTable/>
                    : <ReferenceVersionsTable/>}
        </div>
    )
}
export default DeploymentPage
