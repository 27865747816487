import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageInfo } from 'src/services/api/portfolioApi'
import { useAuthContext } from 'src/context/AuthProvider'
import { useUserQuery } from 'src/services/hooks/useUserQuery'

export const useHooks = () => {
    const { companyId } = useParams()
    const navigate = useNavigate()
    const { isAdmin } = useAuthContext()

    const [currentPage, setCurrentPage] = useState<number | undefined>(
        undefined,
    )
    const [perPage, setPerPage] = useState<number | undefined>(undefined)
    const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined)

    const {
        usersByCompanyData,
        isLoadingUsersByCompanyData,
        refetchUsersByCompanyData,
    } = useUserQuery({ companyId, perPage, page: currentPage })

    useEffect(() => {
        if (!usersByCompanyData?.pagingInfo) return

        const { currentPage, pageCount, perPage, totalCount } =
            usersByCompanyData?.pagingInfo

        setPageInfo({
            currentPage,
            perPage,
            totalCount,
            pageCount,
        })
    }, [usersByCompanyData])

    useEffect(() => {
        refetchUsersByCompanyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, currentPage])

    const handleResetPage = (perPage: number) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

    const usersByCompany = useMemo(() => {
        return (
            usersByCompanyData?.users.sort((a, b) =>
                a.firstname.localeCompare(b.firstname),
            ) || []
        )
    }, [usersByCompanyData?.users])

    const handleAddUser = () => {
        navigate('new')
    }

    const handleClick = (userId: string) => {
        navigate(`/users/${userId}`)
    }

    return {
        isAdmin,
        pageInfo,
        usersByCompany,
        isLoadingUsersByCompanyData,
        handleAddUser,
        handleClick,
        setCurrentPage,
        handleResetPage,
    }
}
