import instance from './axiosInstance'

export const UserRolesEnum = {
    ORGANIZATION_PRODUCT_MANAGER: 'Organization Product Manager',
    ORGANIZATION_ADMIN: 'Organization Admin',
    ADMIN: 'Admin',
}

export type UserRole = 'ORGANIZATION_PRODUCT_MANAGER' | 'ORGANIZATION_ADMIN' | 'ADMIN'

export type Role = {
    uuid: string
    userRole: UserRole
}

export type RoleOption = {
    id: string,
    label: string,
    value: UserRole
}

const RoleApi = {
    getAll: async (): Promise<Role[]> => {
        const config = {
            url: '/roles',
            method: 'GET',
        }

        try {
            const res = await instance.request(config)

            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch all roles: ${error.message}`)
        }
    },
}

export default RoleApi
