// eslint-disable-next-line import/no-anonymous-default-export
export default {
    '1-non-letter-character': 'Ein Sonderzeichen',
    'a-lowercase-character': 'Ein Kleinbuchstabe',
    'an-uppercase-character': 'Ein Großbuchstabe',
    confirm: 'Bestätigen',
    'confirm-password': 'Passwort bestätigen',
    continue: 'Fortsetzen',
    'email-address': 'E-Mail-Adresse',
    'enter-email-address': 'E-Mail-Adresse eingeben',
    'enter-new-password': 'Neues Passwort eingeben',
    'enter-password': 'Passwort eingeben',
    'enter-old-password': 'Altes Passwort eingeben',
    'enter-password-confirmation': 'Passwortbestätigung eingeben',
    'forgot-password': 'Passwort vergessen?',
    'include-at-least-two-of-the-following':
        'Mindestens zwei der folgenden enthalten',
    'log-in': 'Anmelden',
    'login-failed': 'Anmeldung fehlgeschlagen',
    'login-page': 'Anmeldeseite',
    logout: 'Abmelden',
    'minimum-8-characters': 'Mindestens 8 Zeichen',
    'new-password': 'Neues Passwort',
    'new-password-matches-the-old-password':
        'Das neue Passwort stimmt mit dem alten überein',
    password: 'Passwort',
    'old-password': 'Altes Passwort',
    'password-must-be': 'Das Passwort muss beinhalten',
    'please-enter-your-details-to-login': 'Bitte geben Sie Ihre Daten ein, um sich anzumelden',
    'remember-me': 'Angemeldet bleiben',
    'set-new-password': 'Neues Passwort festlegen',
    'welcome-back-to-mms': 'Willkommen zurück bei MMS',
    'password-sent-to-email':
        'Wir haben Ihnen eine E-Mail mit Ihrem neuen Passwort gesendet',
    'reset-password': 'Passwort zurücksetzen',
    'go-back-to': 'Zurück zu',
    'enter-email-to-receive-password':
        'Geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist, und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts.',
    success: 'Erfolg',
    'password-reset-successful': 'Ihr Passwort wurde erfolgreich festgelegt.'
}
