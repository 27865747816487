import {t} from 'i18next'
import {ChevronDownIcon, PhotoIcon} from '@heroicons/react/24/solid'

import Pill from 'src/components/parts/Pill'
import Switch from 'src/components/parts/Switch'
import Detail from 'src/components/parts/Detail'
import Dropdown from 'src/components/parts/Dropdown'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'
import {useAuthContext} from "../../../../context/AuthProvider.tsx";
import {getPillChangeStatusColor} from "../../../../utils/getPillStatusColor.ts";
import React from "react";

const statusOptions = [
    {
        id: 1,
        label: 'ACTIVE',
        value: true,
    },
    {
        id: 2,
        label: 'INACTIVE',
        value: false,
    },
]


const PortfolioItemDetail = () => {
    const {
        portfolioItem,
        isLoadingPortfolioItem,
        handleSetStatus,
        handleSetRepresentant,
    } = useHooks()
    const {company} = useAuthContext()
    let representantSwitchDisabled = company.type === 'DISTRIBUTOR'

    if (isLoadingPortfolioItem) {
        return <LoadingSpinner/>
    }
    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                <BackButton href='/portfolio'/>
                <span className='font-bold text-lg'>
                    {t('back', {ns: 'portfolio'})}
                </span>
            </div>
            {portfolioItem ?
                <div className='flex space-x-8'>
                    <div
                        className='flex justify-center items-center w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'>
                        <PhotoIcon className='w-10 h-10 text-neutral-400'/>
                    </div>

                    <div className='flex flex-col w-full gap-4'>
                        <div className='flex space-x-2 items-center'>
                            <span className='font-bold text-2xl'>
                                {portfolioItem.referenceData.Name}
                            </span>
                            {portfolioItem.changeStatus !==
                                'UNCHANGED' && (
                                    <Pill
                                        label={portfolioItem.changeStatus}
                                        className={`text-white ${getPillChangeStatusColor(portfolioItem.changeStatus)}`}
                                    />
                                )}
                        </div>

                        {/* MAIN DETAILS */}
                        <div
                            className='flex flex-col gap-6 px-6 py-4 bg-neutral-50 border border-neutral-400 rounded-lg'>
                            <span className='font-bold uppercase'>
                                {t('main-details', {ns: 'portfolio'})}
                            </span>
                            <div className='flex flex-wrap text-neutral-900 gap-6'>
                                <Detail
                                    label={t('article-number', {
                                        ns: 'portfolio',
                                    })}
                                    value={portfolioItem.artikelNummer}
                                />
                                <Detail
                                    label={t('article-type', {
                                        ns: 'portfolio',
                                    })}
                                    value={
                                        portfolioItem.referenceData['@class']
                                    }
                                />
                                <Detail
                                    label={t('representative', {
                                        ns: 'portfolio',
                                    })}
                                    value={
                                        <Switch disabled={representantSwitchDisabled}
                                                isSelected={
                                                    portfolioItem.Repraesentant
                                                }
                                                handleSelect={handleSetRepresentant}
                                        />
                                    }
                                />
                                <Detail
                                    label={t('status', {ns: 'portfolio'})}
                                    value={

                                        <Dropdown
                                            options={portfolioItem.Repraesentant ? statusOptions.filter(option => option.id === 1) : statusOptions}
                                            className={`px-8 h-6 !w-fit !rounded-full font-semibold text-xs text-white ${portfolioItem.active ? 'bg-primary-green/80' : 'bg-primary-red/80'}`}
                                            children={
                                                <>
                                                    <span>
                                                        {portfolioItem.active ?
                                                            'ACTIVE'
                                                            : 'INACTIVE'}
                                                    </span>
                                                    <ChevronDownIcon className='h-4 w-4 text-white'/>
                                                </>
                                            }
                                            handleSelectedOption={handleSetStatus}
                                            hoverText={portfolioItem.Repraesentant ? t('cannot-change-status', {ns: 'portfolio'}) : ""}
                                        />

                                    }
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <span className='text-neutral-400 uppercase'>
                                    {t('article-type', {ns: 'portfolio'})}
                                </span>
                                <div className='flex space-x-2 font-semibold text-neutral-700'>
                                    <span>EN</span>
                                    <span>
                                        {portfolioItem.referenceData.en}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* SECONDARY DETAILS */}
                        <div
                            className='flex flex-col gap-6 px-6 py-4 bg-neutral-50 border border-neutral-400 rounded-lg'>
                            <span className='font-bold uppercase'>
                                {t('secondary-details', {ns: 'portfolio'})}
                            </span>
                            <div className='flex flex-wrap text-neutral-900 gap-10'>
                                {Object.entries(
                                    JSON.parse(
                                        portfolioItem.referenceData.extendInfo,
                                    ),
                                ).map(([key, value], index) => {
                                    return (
                                        <Detail
                                            key={index}
                                            label={key}
                                            value={value as string}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                : <NoDetailsToShow/>}
        </div>
    )
}
export default PortfolioItemDetail
