import { t } from 'i18next'
import { ReactNode } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/parts/Button'

type ModalProps = {
    label: string
    content: string | ReactNode
    handleCancel: () => void
    handleConfirm: () => void
    confirmMessage?: string
    cancelMessage?: string
}

const Modal = ({
    label,
    content,
    handleCancel,
    handleConfirm,
    confirmMessage = t('yes'),
    cancelMessage = t('no'),
}: ModalProps) => {
    const handleCloseModal = () => {
        handleCancel()
    }

    return (
        <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50 z-50'>
            <div className='absolute flex flex-col items-center max-w-[520px] gap-6 p-5 rounded-lg bg-white'>
                <div className='flex justify-between w-full'>
                    <span className='text-base font-bold '>{label}</span>
                    <button onClick={handleCloseModal}>
                        <XMarkIcon className='h-6 w-6' />
                    </button>
                </div>
                <span>{content}</span>
                <div className='flex w-full justify-end gap-2'>
                    <Button
                        onClick={handleCancel}
                        variant='outlined'
                        color='primary-dark'
                        label={cancelMessage}
                    />
                    <Button
                        onClick={handleConfirm}
                        variant='contained'
                        color='primary-dark'
                        label={confirmMessage}
                    />
                </div>
            </div>
        </div>
    )
}
export default Modal
