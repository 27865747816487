import toast from 'react-hot-toast'
import {useQuery} from 'react-query'

import PortfolioApi from 'src/services/api/portfolioApi'

type PortfolioTypesQueryProps = {
    companyId?: string
}

export const usePortfolioTypesQuery = ({
                                           companyId
                                       }: PortfolioTypesQueryProps) => {
    const {getTypes} = PortfolioApi


    const {
        data: portfolioTypes
    } = useQuery({
        queryKey: ['portfolioTypes', companyId],
        queryFn: () =>
            companyId ? getTypes({companyId}) : undefined,
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    return {
        portfolioTypes
    }
}
