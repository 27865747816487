import { t } from 'i18next'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import Modal from 'src/components/parts/Modal'
import Button from 'src/components/parts/Button'
import Dropdown from 'src/components/parts/Dropdown'
import InputField from 'src/components/parts/InputField'
import { UserRolesEnum } from 'src/services/api/roleApi'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'

import { useHooks } from './hooks'

const CreateCompanyUser = () => {
    const {
        role,
        errors,
        company,
        roleOptions,
        selectedRole,
        isLoadingCreateUser,
        isShowConfirmationModal,
        register,
        onSubmit,
        handleBack,
        handleSubmit,
        handleCancel,
        handleAddUser,
        handleSelectRole,
    } = useHooks()

    if (isLoadingCreateUser) {
        return <LoadingSpinner />
    }

    return (
        <div className='flex flex-col gap-6'>
            <div className='flex items-center gap-4 text-primary-dark'>
                <BackButton />
                <span className='font-bold text-2xl'>
                    {t('add-user', { ns: 'company' })}
                </span>
            </div>
            <div className='flex mx-10 space-x-8'>
                <div className='flex flex-col w-full gap-10'>
                    <div className='flex gap-2'>
                        <InputField
                            id='firstname'
                            register={register}
                            placeholder={t('enter-first-name', {
                                ns: 'company',
                            })}
                            label={t('first-name', { ns: 'company' })}
                            error={errors?.firstname?.message}
                        />
                        <InputField
                            id='lastname'
                            register={register}
                            placeholder={t('enter-last-name', {
                                ns: 'company',
                            })}
                            label={t('last-name', { ns: 'company' })}
                            error={errors?.lastname?.message}
                        />
                    </div>
                    <div className='flex gap-2'>
                        <InputField
                            id='email'
                            register={register}
                            placeholder={t('enter-name', { ns: 'company' })}
                            label={t('email-address', { ns: 'company' })}
                            error={errors?.email?.message}
                        />
                        <InputField
                            id='password'
                            register={register}
                            type='password'
                            placeholder={t('password', { ns: 'company' })}
                            label={t('password', { ns: 'company' })}
                            error={errors?.password?.message}
                        />
                    </div>
                    <div className='flex w-1/2'>
                        <div className='flex flex-col w-full gap-2'>
                            <label
                                htmlFor='role'
                                className='text-neutral-700 text-sm font-semibold uppercase'
                            >
                                {t('role', {
                                    ns: 'company',
                                })}
                                {errors?.role?.message && (
                                    <span className='text-primary-red text-sm'>
                                        {!role && '*'}
                                    </span>
                                )}
                            </label>
                            <Dropdown
                                options={roleOptions ?? []}
                                handleSelectedOption={handleSelectRole}
                                className='!rounded-[4px] bg-white border border-neutral-300'
                            >
                                <div className='flex gap-1.5 w-full justify-between'>
                                    <span
                                        className={`text-nowrap ${selectedRole ?? 'text-neutral-400'} `}
                                    >
                                        {(selectedRole &&
                                            UserRolesEnum[selectedRole]) ??
                                            t('select-role', {
                                                ns: 'company',
                                            })}
                                    </span>
                                </div>
                                <ChevronDownIcon className='h-4 w-4 text-neutral-700' />
                            </Dropdown>
                        </div>
                    </div>
                    <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                        <Button
                            onClick={handleBack}
                            variant='outlined'
                            color='primary-dark'
                            label={t('cancel', { ns: 'company' })}
                        />
                        <Button
                            onClick={handleSubmit(handleAddUser)}
                            variant='contained'
                            color='primary-dark'
                            label={t('add-user', { ns: 'company' })}
                        />
                    </div>
                </div>
            </div>
            {isShowConfirmationModal && (
                <Modal
                    label='Add New User'
                    content={
                        <>
                            {t('confirm-adding-new-user-to', { ns: 'company' })}{' '}
                            <span className='font-bold'>{company?.name}</span>?
                        </>
                    }
                    handleCancel={handleCancel}
                    handleConfirm={handleSubmit(onSubmit)}
                />
            )}
        </div>
    )
}

export default CreateCompanyUser
