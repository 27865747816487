import {t} from 'i18next'
import {PlusIcon} from '@heroicons/react/24/solid'

import {toTitleCase} from 'src/utils/toTitleCase'
import IconButton from 'src/components/parts/IconButton'
import {UserRolesEnum} from 'src/services/api/roleApi'
import Paginator from 'src/components/templates/Paginator'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'
import Button from "../../../../components/parts/Button";

const CompanyUsers = () => {
    const {
        isAdmin,
        pageInfo,
        usersByCompany,
        isLoadingUsersByCompanyData,
        handleAddUser,
        handleClick,
        setCurrentPage,
        handleResetPage,
    } = useHooks()

    if (isLoadingUsersByCompanyData) {
        return <LoadingSpinner/>
    }

    const CompanyUsersList = () => {
        return (
            <div>
                <div className='table-container max-h-[calc(100vh-240px)] overflow-y-auto'>
                    <table className='w-full text-sm text-left bg-neutral-50'>
                        <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-60 px-8 py-5 text-gray-700'
                            >
                                {t('name', {ns: 'company'})}
                            </th>
                            <th
                                scope='col'
                                className='w-96 w-min-60 px-8 py-5 text-gray-700'
                            >
                                {t('email-address', {ns: 'company'})}
                            </th>
                            <th
                                scope='col'
                                className='w-60 px-8 py-5 text-gray-700'
                            >
                                {t('role', {ns: 'company'})}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700'
                            >
                                {t('status', {ns: 'company'})}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700'
                            ></th>
                        </tr>
                        </thead>
                        <tbody>
                        {usersByCompany && usersByCompany.length > 0 ?
                            usersByCompany?.map(
                                ({
                                     uuid,
                                     firstname,
                                     lastname,
                                     email,
                                     role,
                                     status,
                                 }) => {
                                    return (
                                        <tr
                                            key={uuid}
                                            className='border-neutral-200 border-b-[1px]'
                                        >
                                            <td className='px-8 py-4 whitespace-nowrap'>
                                                {toTitleCase(
                                                    ` ${firstname} ${lastname}`,
                                                )}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap'>
                                                {email}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap truncate'>
                                                {
                                                    UserRolesEnum[
                                                        role.userRole
                                                        ]
                                                }
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap'>
                                                {status}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap text-end'>
                                                <Button
                                                    onClick={() => handleClick(uuid)}
                                                    label={t('details', {ns: 'company'})}
                                                    className='text-white bg-primary-dark'
                                                />
                                            </td>
                                        </tr>
                                    )
                                },
                            )
                            : <tr>
                                <td colSpan={4}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pageInfo && (
                    <Paginator
                        pageInfo={pageInfo}
                        handleSetPerPage={handleResetPage}
                        handleSetCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        )
    }
    return (
        <div className='flex flex-col gap-4'>
            <div className={`absolute right-0 top-0 ${isAdmin && 'mr-10'}`}>
                <IconButton
                    onClick={handleAddUser}
                    icon={<PlusIcon className='h-4 w-4 text-white'/>}
                    label={t('add-user', {ns: 'company'})}
                    className='text-white bg-primary-dark'
                />
            </div>
            <CompanyUsersList/>
        </div>
    )
}

export default CompanyUsers
