import {t} from 'i18next'
import {Link} from 'react-router-dom'
import {PlusIcon, UserCircleIcon} from '@heroicons/react/24/solid'

import Pill from 'src/components/parts/Pill'
import {toTitleCase} from 'src/utils/toTitleCase'
import IconButton from 'src/components/parts/IconButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'
import Button from "../../components/parts/Button";

const CompanyList = () => {
    const {
        filters,
        activeFilter,
        filteredCompanies,
        isLoadingCompanies,
        getCount,
        handleSelectTab,
        handleAddCompany,
    } = useHooks()

    if (isLoadingCompanies) {
        return <LoadingSpinner/>
    }

    const CompanyTable = () => {
        return (
            <div className='table-container max-h-[calc(100vh-256px)] overflow-y-auto'>
                <table className='w-full text-sm text-left'>
                    <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                    <tr>
                        <th
                            scope='col'
                            className='w-60 px-8 py-5 text-gray-700 whitespace-nowrap'
                        >
                            {t('category', {ns: 'company'})}
                        </th>
                        <th
                            scope='col'
                            className='w-96  px-8 py-5 text-gray-700 whitespace-nowrap'
                        >
                            {t('name', {ns: 'company'})}
                        </th>
                        <th
                            scope='col'
                            className='max-w-24 px-8 py-5 text-gray-700 whitespace-nowrap'
                        >
                            {t('production-instance-url', {
                                ns: 'company',
                            })}
                        </th>
                        <th
                            scope='col'
                            className='max-w-24 px-8 py-5 text-gray-700 whitespace-nowrap'
                        >

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCompanies.length > 0 ?
                        filteredCompanies.map((company) => {
                            return (
                                <tr
                                    key={company.uuid}
                                    className='group bg-neutral-50 border-neutral-200 border-b-[1px] hover:bg-neutral-100'
                                >
                                    <td className='px-8 py-4 whitespace-nowrap'>
                                        {toTitleCase(
                                            t(
                                                `${company.type.toLowerCase()}`,
                                                {
                                                    ns: 'company',
                                                },
                                            ),
                                        )}
                                    </td>
                                    <td className='px-8 py-4 group-hover:underline'>
                                        <div className='flex items-center space-x-2 whitespace-nowrap'>
                                            <UserCircleIcon className='h-8 w-8 text-neutral-700 flex-shrink-0'/>
                                            <Link
                                                to={`${company.uuid}/details`}
                                            >
                                                {company.name}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className='px-8 py-5 whitespace-nowrap overflow-hidden  overflow-ellipsis text-info'
                                        style={{minWidth: '200px'}}>
                                        <Link
                                            to={`${company['production-instance-url']}`}
                                            className='underline'
                                        >
                                            {
                                                company[
                                                    'production-instance-url'
                                                    ]
                                            }
                                        </Link>
                                    </td>
                                    <td className='px-8 py-5 whitespace-nowrap overflow-hidden  overflow-ellipsis text-info'>
                                        <div className=' bottom-0 right-0 flex justify-end w-full'>
                                            <Link
                                                to={`${company.uuid}/details`}
                                            >
                                                <Button
                                                    label={t('details', {ns: 'company'})}
                                                    className='text-white bg-primary-dark'
                                                />
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        : <tr>
                            <td colSpan={3}>
                                <NoDetailsToShow/>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className='flex flex-col w-full gap-10'>
            <div className='flex justify-between w-full'>
                <div className='flex gap-6'>
                    {filters.map(({id, label, type}) => {
                        return (
                            <div
                                key={id}
                                className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer ${activeFilter === type && 'text-primary-dark border-b-2 border-primary-dark'}`}
                                onClick={() => handleSelectTab(type)}
                            >
                                {t(`${label}`, {ns: 'company'})}
                                <Pill
                                    className={`min-w-7 text-white ${activeFilter === type ? 'bg-primary-dark' : 'bg-neutral-700'}`}
                                    label={getCount(type)}
                                />
                            </div>
                        )
                    })}
                </div>
                <IconButton
                    onClick={handleAddCompany}
                    icon={<PlusIcon className='h-4 w-4 text-white'/>}
                    label={t('add-new-company', {ns: 'company'})}
                    className='text-white bg-primary-dark'
                />
            </div>
            <CompanyTable/>
        </div>
    )
}

export default CompanyList
