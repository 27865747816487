import { t } from 'i18next'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useParams } from 'react-router-dom'

import { useRoleQuery } from 'src/services/hooks/useRoleQuery'
import { useUserQuery } from 'src/services/hooks/useUserQuery'
import { Role, UserRole, UserRolesEnum } from 'src/services/api/roleApi'
import {
    UserSchemaWithoutPassword,
    userSchemaWithoutPassword,
} from 'src/services/api/userApi'

export const useHooks = () => {
    const navigate = useNavigate()
    const { userId } = useParams()
    const { roles } = useRoleQuery()
    const {
        user: userData,
        isLoadingUser,
        updateUser,
        isLoadingUpdateUser,
    } = useUserQuery({ userId })

    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const filteredRoles = roles?.filter((role) => {
        return role.userRole !== null
    })
    const roleOptions = filteredRoles?.map(({ uuid, userRole }: Role) => {
        return {
            id: uuid,
            label: UserRolesEnum[userRole],
            value: userRole,
        }
    })

    const {
        register,
        setValue,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<UserSchemaWithoutPassword>({
        resolver: zodResolver(userSchemaWithoutPassword),
    })

    const user = useWatch({ control })

    useEffect(() => {
        if (userData) {
            setValue('firstname', userData.firstname)
            setValue('lastname', userData.lastname)
            setValue('email', userData.email)
            setValue('role', userData.role)
        }
    }, [userData, setValue])

    const handleSelectRole = (selectedRole: UserRole) => {
        const findUserRole = (): Role | undefined => {
            return roles?.find(({ userRole }) => userRole === selectedRole)
        }

        const userRole = findUserRole()

        if (userRole) {
            setValue('role', userRole)
        }
    }

    const handleEditModeToggle = () => {
        setIsEditMode(!isEditMode)
    }

    const handleSaveChanges = async (data: UserSchemaWithoutPassword) => {
        try {
            if (userId) {
                await updateUser({ userId, ...data })
                toast.success(
                    `${t('successfully-updated-user', { ns: 'company' })}`,
                )

                !isLoadingUpdateUser && navigate(-1)
            }
        } catch (error: any) {
            toast.error(error.message)
        }
        setIsEditMode(!isEditMode)
    }

    return {
        user,
        errors,
        isEditMode,
        roleOptions,
        isLoadingUser,
        register,
        handleSubmit,
        handleSelectRole,
        handleSaveChanges,
        handleEditModeToggle,
    }
}
