import { ReactNode } from 'react'

type DisplayFieldProps = { label: string; value?: string | null | ReactNode }

const DisplayField = ({ label, value }: DisplayFieldProps) => {
    return (
        <div className='flex flex-col w-full gap-1'>
            <label className='text-neutral-700 text-sm font-semibold uppercase'>
                {label}
            </label>
            <span className='flex items-center h-10 font-bold'>{value}</span>
        </div>
    )
}

export default DisplayField
