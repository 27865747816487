import { t } from 'i18next'

const NoDetailsToShow = () => {
    return (
        <div className='flex justify-center px-6 py-5 italic text-neutral-700'>
            {t('no-details-to-show', { ns: 'common' })}
        </div>
    )
}

export default NoDetailsToShow
