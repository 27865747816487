import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAuthContext } from 'src/context/AuthProvider'
import { useProdDeploymentQuery } from 'src/services/hooks/useProdDeploymentQuery'

type ImportError = {
    itemNumber: string
    errorMessage: string
}

export const useHooks = () => {
    const { deploymentId } = useParams()
    const { company } = useAuthContext()
    const companyId = company?.uuid

    const { prodDeployment, isLoadingProdDeployment } = useProdDeploymentQuery({
        companyId,
        deploymentId,
    })

    const [importErrors, setImportErrors] = useState<ImportError[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        if (!prodDeployment?.message) return

        const handleDeploymentErrorMessage = (message: string) => {
            try {
                const parsedMessage = JSON.parse(message)
                const { importErrors = [] } = parsedMessage
                setImportErrors(importErrors)
            } catch (e) {
                setErrorMessage(message)
            }
        }

        handleDeploymentErrorMessage(prodDeployment.message)
    }, [prodDeployment])

    return {
        prodDeployment,
        isLoadingProdDeployment,
        importErrors,
        errorMessage,
    }
}
