import { ReactNode, useEffect, useRef, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

import IconButton from 'src/components/parts/IconButton'

type ScrollableSliderTabsProps = {
    children: ReactNode
}
const ScrollableSliderTabs = ({ children }: ScrollableSliderTabsProps) => {
    const [isOverflowing, setIsOverflowing] = useState(false)

    const containerRef = useRef<HTMLDivElement | null>(null)

    const manageSlider = () => {
        const container = containerRef.current
        const leftButton = document.getElementById('left-button')
        const rightButton = document.getElementById('right-button')

        if (container && leftButton && rightButton) {
            const maxScrollValue = container.scrollWidth - container.clientWidth

            if (container.scrollLeft >= 20) {
                leftButton.classList.remove('hidden')
            } else {
                leftButton.classList.add('hidden')
            }

            if (container.scrollLeft >= maxScrollValue - 20) {
                rightButton.classList.add('hidden')
            } else {
                rightButton.classList.remove('hidden')
            }
        }
    }

    const scrollLeft = () => {
        const container = containerRef.current
        if (container) {
            container.scrollLeft -= container.clientWidth * 0.8
            manageSlider()
        }
    }

    const scrollRight = () => {
        const container = containerRef.current
        if (container) {
            container.scrollLeft += container.clientWidth * 0.8
            manageSlider()
        }
    }

    useEffect(() => {
        const container = containerRef.current

        if (container) {
            setIsOverflowing(container.scrollWidth > container.clientWidth)
        }

        window.addEventListener('resize', manageSlider)

        return () => {
            window.removeEventListener('resize', manageSlider)
        }
    }, [])

    return (
        <div className='relative flex gap-2'>
            <IconButton
                id='left-button'
                onClick={scrollLeft}
                className={`bg-gradient-to-r bg-neutral-200 hidden`}
                icon={
                    <ChevronLeftIcon className='h-4 w-4 text-neutral-700 group-hover:text-primary-dark' />
                }
            />
            <div
                ref={containerRef}
                id='filter-container'
                className='flex gap-2 overflow-auto scroll-smooth'
                style={{ scrollbarWidth: 'none' }}
                onScroll={manageSlider}
            >
                {children}
            </div>
            <IconButton
                id='right-button'
                onClick={scrollRight}
                className={`bg-neutral-200 ${!isOverflowing && 'hidden'}`}
                icon={
                    <ChevronRightIcon className='h-4 w-4 text-neutral-700 group-hover:text-primary-dark' />
                }
            />
        </div>
    )
}

export default ScrollableSliderTabs
