import { ReactNode, createContext, useContext, useState } from 'react'

import { User } from 'src/services/api/userApi'
import { Company } from '../services/api/companyApi'

export type AuthName = 'Admin' | 'Org Admin' | 'Org PM'

export type Auth = {
    user?: User | null
    token: string | null
    company: Company | null
}

type AuthContextType = {
    auth: Auth | null
    setAuth: (newValue: any) => void
    isAdmin: boolean | null
    company: Company | null | undefined
    setCompany: (newValue: any) => void
}

const initialState = {
    auth: null,
    setAuth: () => {},
    isAdmin: null,
    company: null,
    setCompany: () => {},
}

const AuthContext = createContext<AuthContextType>(initialState)

const AuthProvider = ({ children }: { children?: ReactNode }) => {
    const [auth, setAuth] = useState<Auth | null>(initialState.auth)
    const [company, setCompany] = useState<Company | null>(null)

    const isAdmin = auth?.user?.role?.userRole === 'ADMIN'

    return (
        <AuthContext.Provider
            value={{ isAdmin, auth, setAuth, company, setCompany }}
        >
            {children}
        </AuthContext.Provider>
    )
}

const useAuthContext = () => {
    return useContext(AuthContext)
}

export { AuthContext, AuthProvider, useAuthContext }
