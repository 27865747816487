import React from 'react'
import ReactDOM from 'react-dom/client'

import './lib/i18n'
import App from 'src/App'
import 'src/styles/css/styles.css'
import { AuthProvider } from './context/AuthProvider'
import { QueryClient, QueryClientProvider } from 'react-query'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </AuthProvider>
    </React.StrictMode>,
)
