import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {useLocation, useNavigate} from 'react-router-dom'

import CompanyApi from 'src/services/api/companyApi'
import {useAuthContext} from 'src/context/AuthProvider'
import {setBearerToken} from 'src/services/api/axiosInstance'
import AuthApi, {LoginParams, PasswordChangeParams,} from 'src/services/api/authApi'

import useCookies from './useCookie'

export const useAuthApi = () => {
    const cookies = useCookies()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const location = useLocation()
    const {setAuth, setCompany} = useAuthContext()

    const login = async (params: LoginParams) => {
        const {email, password} = params
        params.email = params.email.toLowerCase()
        const referringPath = location?.state?.from

        const redirectTo =
            referringPath === 'set-password' ? '/' : referringPath || '/'

        try {
            const res = await AuthApi.login(params)
            const authToken = res.data.token

            if (!authToken) return

            setBearerToken(authToken)
            cookies.set('token', authToken)

            const authUser = await AuthApi.me()
            setAuth({token: authToken, user: authUser})

            if (authUser.role.userRole !== 'ADMIN') {
                const companyData = await CompanyApi.get({
                    companyId: authUser.organizationId,
                })

                setCompany(companyData)
            }

            const homeUrl =
                authUser.role?.userRole === 'ADMIN' ? '/company' : '/portfolio'

            navigate(referringPath === '/' ? homeUrl : redirectTo, {
                replace: true,
            })
        } catch (err: any) {
            if (err.response?.status === 400) {
                navigate(`/set-password?email=${email}&password=${password}`)
                return
            }

            throw new Error(err)
        }
    }

    const loginAsNewUser = async (params: LoginParams) => {
        const {password, newPassword} = params

        if (password === newPassword) {
            throw new Error('Cannot use old password as new password')
        }

        try {
            const res = await AuthApi.login(params)
            return res
        } catch (err: any) {
            if (err.response.status === 401) {
                navigate(`/login`)
                throw new Error('Invalid credentials')
            } else {
                throw new Error(err)
            }
        }
    }

    const logout = async () => {
        try {
            const res = await AuthApi.logout()
            if (res.status === 200) {
                toast.dismiss()
                cookies.remove('token')
                queryClient.removeQueries()

                setAuth(null)
                navigate('/login', {state: {from: '/'}, replace: true})
            }
        } catch (err: any) {
            throw new Error(err)
        }
    }

    const changePassword = async (data: PasswordChangeParams) => {
        try {
            await AuthApi.changePassword(data)
            await logout()

            toast.success('Password change successful. Please log in again.', {
                duration: 5 * 1000,
            })
        } catch (err: any) {
            throw new Error(err.message)
        }
    }

    return {
        login,
        logout,
        loginAsNewUser,
        changePassword,
    }
}
