import { useQuery } from 'react-query'

import RoleApi from 'src/services/api/roleApi'

export const useRoleQuery = () => {
    const { getAll } = RoleApi

    const { data: roles, isLoading: isLoadingRoles } = useQuery({
        queryKey: ['roles'],
        queryFn: () => getAll(),
    })

    return {
        roles,
        isLoadingRoles
    }
}
