import { ReactNode } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

type ToastWidgetProps = {
    label: string
    message: string | ReactNode
    handleClose?: () => void
}

const ToastWidget = ({ label, message, handleClose }: ToastWidgetProps) => {
    return (
        <div className='relative flex items-center gap-4'>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col'>
                    <span className='text-base font-bold'>{label}</span>

                    <span className='text-sm text-neutral-700'>{message}</span>
                </div>
            </div>
            {handleClose && (
                <button onClick={handleClose}>
                    <XMarkIcon className='h-4 w-4 text-primary-dark' />
                </button>
            )}
        </div>
    )
}

export default ToastWidget
