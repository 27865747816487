import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import Button from 'src/components/parts/Button'

const Unauthorized = () => {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col w-full h-screen justify-center items-center gap-4'>
            <span className='text-2xl font-bold uppercase'>
                {t('unauthorized', { ns: 'common' })}
            </span>
            <div className='flex flex-col w-[400px] gap-10'>
                <span>{t('unauthorized-message', { ns: 'common' })}</span>
                <div className='flex w-full gap-2'>
                    <Button
                        label='Go back'
                        onClick={() => navigate(-1)}
                        variant='outlined'
                        color='primary-dark'
                        className='flex w-1/3'
                    />
                    <Button
                        label='Login'
                        onClick={() =>
                            navigate('/login', { state: { from: '/' } })
                        }
                        variant='contained'
                        color='primary-dark'
                        className='flex w-2/3'
                    />
                </div>
            </div>
        </div>
    )
}
export default Unauthorized
