import { UseFormRegister } from 'react-hook-form'
import { toTitleCase } from 'src/utils/toTitleCase'

type InputRadioProps = {
    id: string
    name: string
    label: string
    value: string
    className?: string
    disabled?: boolean
    register?: UseFormRegister<any>
}
const InputRadio = ({
    id,
    name,
    label,
    className,
    value,
    disabled,
    register,
}: InputRadioProps) => {
    return (
        <div className='flex space-x-2'>
            <input
                type='radio'
                id={id}
                {...(register && { ...register(name) })}
                value={value}
                disabled={disabled}
            />
            <label htmlFor={id} className={className}>
                {toTitleCase(label)}
            </label>
        </div>
    )
}
export default InputRadio
