import {t} from 'i18next'
import moment from 'moment-timezone'

import Pill from 'src/components/parts/Pill'
import Detail from 'src/components/parts/Detail'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import {getPillStatusColor} from 'src/utils/getPillStatusColor'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'

interface DeploymentErrorProps {
    environment?: string
}

const DeploymentError = ({environment}: DeploymentErrorProps) => {
    const {
        prodDeployment,
        isLoadingProdDeployment,
        importErrors,
        errorMessage,
    } = useHooks()

    if (isLoadingProdDeployment) {
        return <LoadingSpinner/>
    }

    if (!prodDeployment) {
        return <NoDetailsToShow/>
    }

    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                <BackButton href={'/deployment/' + environment}/>
                <span className='font-bold text-lg'>Back</span>
            </div>
            <div className='flex flex-col w-full gap-4'>
                <div className='flex flex-col gap-6 px-6 py-4 bg-neutral-50 border border-neutral-400 rounded-lg'>
                    <span className='font-bold uppercase'>Details</span>
                    <div className='flex gap-6'>
                        <Detail
                            label={t('environment', {ns: 'deployment'})}
                            value={prodDeployment.deploymentEnvironment}
                        />
                        <Detail
                            label={t('deployment-date', {ns: 'deployment'})}
                            value={moment(prodDeployment.deploymentDate).format(
                                'DD.MM.YYYY, h:mm A',
                            )}
                        />
                    </div>
                    <div className='flex gap-6'>
                        <Detail
                            label={t('requested-by', {ns: 'deployment'})}
                            value={prodDeployment.requestedBy}
                        />
                        <Detail
                            label={t('status', {ns: 'deployment'})}
                            value={
                                <Pill
                                    className={`w-fit h-6 text-white ${getPillStatusColor(prodDeployment.deploymentStatus)}`}
                                    label={prodDeployment.deploymentStatus}
                                />
                            }
                        />
                    </div>

                    <Detail
                        classname='flex w-full'
                        label='url'
                        value={prodDeployment.url}
                    />

                    {errorMessage && (
                        <Detail
                            classname='flex w-full'
                            label={t('message', {ns: 'deployment'})}
                            value={errorMessage}
                        />
                    )}

                    {importErrors.length > 0 && (
                        <>
                            <Detail
                                classname='flex w-full'
                                label={t('message', {ns: 'deployment'})}
                                value={t('import-errors-found', {
                                    ns: 'deployment',
                                })}
                            />
                            <div className='table-container w-fit max-h-96 overflow-y-auto'>
                                <table
                                    className='max-w-[600px] text-sm text-left bg-neutral-50 border-[1px] border-neutral-200'>
                                    <thead className='sticky top-0 text-sm uppercase bg-neutral-200 z-10'>
                                    <tr>
                                        <th
                                            scope='col'
                                            className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                                        >
                                            {t('article-number', {
                                                ns: 'deployment',
                                            })}
                                        </th>
                                        <th
                                            scope='col'
                                            className='w-96 px-8 py-5 text-gray-700 whitespace-nowrap '
                                        >
                                            {t('error', {
                                                ns: 'deployment',
                                            })}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {importErrors.map(
                                        (
                                            {itemNumber, errorMessage},
                                            index,
                                        ) => (
                                            <tr
                                                key={index}
                                                className='group border-neutral-200 border-b-[1px] hover:bg-neutral-100'
                                            >
                                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                                    {itemNumber}
                                                </td>
                                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                                    {errorMessage}
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DeploymentError
