import { useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { UseFormRegister } from 'react-hook-form'

type InputFieldProps = {
    id: string
    placeholder: string
    label: string
    type?: string
    icon?: boolean
    error?: string | boolean
    disabled?: boolean
    register?: UseFormRegister<any>
}

const InputField: React.FC<InputFieldProps> = ({
    id,
    placeholder,
    label,
    error,
    type = 'text',
    disabled,
    register,
}) => {
    const [isShow, setIsShow] = useState<boolean>(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <div className='flex flex-col w-full gap-2'>
            <label
                htmlFor={id}
                className={`text-neutral-700 text-sm font-semibold uppercase ${disabled && 'text-disabled'}`}
            >
                {label?.toUpperCase()}
                {error && <span className='text-primary-red text-sm'> *</span>}
            </label>
            <div className='relative'>
                <input
                    id={id}
                    {...(register && { ...register(id) })}
                    placeholder={placeholder}
                    type={isShow ? 'text' : type}
                    className={`relative h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px] checked:bg-primary-green checked:text-white placeholder:font-light placeholder:text-neutral-400 ${disabled && 'placeholder:text-disabled'}`}
                    disabled={disabled}
                />
                {type === 'password' && (
                    <button
                        type='button'
                        onClick={handleShow}
                        className='absolute top-0 right-0 -translate-x-1/2 flex items-center justify-center h-full text-neutral-700'
                    >
                        {isShow ?
                            <EyeIcon className='h-6 w-6 text-neutral-700' />
                        :   <EyeSlashIcon className='h-6 w-6 text-neutral-700' />
                        }
                    </button>
                )}
            </div>
        </div>
    )
}

export default InputField
