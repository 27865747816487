import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'

type BackButtonProps = {
    href?: string
}
const BackButton = ({ href }: BackButtonProps) => {
    const navigate = useNavigate()

    const handleBack = () => {
        href ? navigate(href) : navigate(-1)
    }
    return (
        <button onClick={handleBack} className='h-10 cursor-pointer'>
            <ArrowLeftIcon className='h-6 w-6' />
        </button>
    )
}
export default BackButton
