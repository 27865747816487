import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { UserRole } from 'src/services/api/roleApi'
import Unauthorized from 'src/pages/error/unauthorized'
import { useAuthContext } from 'src/context/AuthProvider'

const RequireAuth = ({ allowedRoles }: { allowedRoles: UserRole[] }) => {
    const { auth } = useAuthContext()
    const location = useLocation()

    if (!auth || !auth.user || !auth.token) {
        return <Navigate to='/login' state={{ from: location }} replace />
    }

    if (allowedRoles.includes(auth.user.role.userRole)) {
        return <Outlet />
    } else {
        return <Unauthorized />
    }
}

export default RequireAuth
