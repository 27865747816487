import { ReactNode } from 'react'

type ButtonProps = {
    label: string | ReactNode
    color?: 'primary-green' | 'primary-dark'
    variant?: 'contained' | 'outlined'
    className?: string
    disabled?: boolean
    onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({
    variant,
    color = 'primary-dark',
    label,
    className,
    disabled,
    onClick,
}) => {
    const buttonClassName = `flex justify-center items-center h-[36px] px-4 py-1 text-sm font-semibold rounded-lg whitespace-nowrap ${disabled && 'text-primary-400'} ${className}`

    if (variant === 'contained') {
        return (
            <button
                onClick={onClick}
                className={`text-white bg-${color} ${disabled && '!bg-neutral-400'} ${buttonClassName}`}
                disabled={disabled}
            >
                {label}
            </button>
        )
    }

    if (variant === 'outlined') {
        return (
            <button
                onClick={onClick}
                className={`border border-${color} ${disabled && '!text-neutral-400 !border-neutral-400'} ${buttonClassName}`}
                disabled={disabled}
            >
                {label}
            </button>
        )
    }

    return (
        <button
            onClick={onClick}
            className={`text-${color} ${disabled && '!text-neutral-400'} ${buttonClassName}`}
            disabled={disabled}
        >
            {label}
        </button>
    )
}
export default Button
