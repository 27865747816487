import { ArrowPathIcon } from '@heroicons/react/24/solid'

type LoadingSpinnerProps = {
    classname?: string
}
const LoadingSpinner = ({ classname }: LoadingSpinnerProps) => {
    return (
        <div className='inset-0 flex justify-center'>
            <ArrowPathIcon className={`animate-spin h-5 w-5 ${classname}`} />
        </div>
    )
}

export default LoadingSpinner
