import {t} from 'i18next'
import {Link} from 'react-router-dom'
import {ChevronDownIcon} from '@heroicons/react/24/solid'

import Pill from 'src/components/parts/Pill'
import Modal from 'src/components/parts/Modal'
import Switch from 'src/components/parts/Switch'
import Button from 'src/components/parts/Button'
import Dropdown from 'src/components/parts/Dropdown'
import Paginator from 'src/components/templates/Paginator'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'
import ScrollableSliderTabs from 'src/components/templates/SliderTabs'
import {getPillChangeStatusColor} from 'src/utils/getPillStatusColor'
import LoadingCard from 'src/components/parts/LoadingCard'

import {useHooks} from './hooks'
import React, {useState} from "react";

const PortfolioTablePage = () => {
    const {
        pageInfo,
        company,
        filters,
        activeFilter,
        statusOptions,
        filteredPortfolioItems,
        isLoadingPortfolioData,
        isShowPublishConfirmationModal,
        setCurrentPage,
        getCount,
        rollback,
        navigate,
        handleResetPage,
        handleSetStatus,
        handleSelectTab,
        publishReferenceData,
        handleSetRepresentant,
        setIsShowPublishConfirmationModal,
        isSupplierCompany
    } = useHooks()

    const [isPublishing, setIsPublishing] = useState(false)
    const [isShowPublishSpinnerModal, setIsShowPublishSpinnerModal] = useState(false)

    if (isLoadingPortfolioData) {
        return <LoadingSpinner/>
    }

    const PortfolioItemTable = () => {
        return (
            <div className='flex flex-col'>
                <div className='table-container max-h-[calc(100vh-360px)] overflow-y-auto'>
                    <table className='w-full text-sm text-left bg-neutral-50'>
                        <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-80 max-w-80  px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('article-number', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='w-[600px] w-max-[600px] px-8 py-5 text-gray-700 whitespace-nowrap '
                            >
                                {t('name', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='w-40 max-w-60 px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('representative', {
                                    ns: 'portfolio',
                                })}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('status', {
                                    ns: 'portfolio',
                                })}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredPortfolioItems.length > 0 ?
                            filteredPortfolioItems.map((portfolioItem) => {
                                const {
                                    uuid,
                                    active,
                                    artikelNummer,
                                    referenceData,
                                    Repraesentant,
                                    changeStatus,
                                } = portfolioItem

                                return (
                                    <tr
                                        key={uuid}
                                        className='group border-neutral-200 border-b-[1px] hover:bg-neutral-100'
                                    >
                                        <td className='w-40 max-w-80 px-8 py-4 whitespace-nowrap'>
                                            {artikelNummer}
                                        </td>
                                        <td className='flex w-[400px] w-max-[600px] gap-2 px-8 py-4'>
                                            <Link to={`item/${uuid}`}>
                                                {referenceData.Name}
                                            </Link>
                                            {changeStatus !==
                                                'UNCHANGED' && (
                                                    <Pill
                                                        label={changeStatus}
                                                        className={`text-white ${getPillChangeStatusColor(changeStatus)}`}
                                                    />
                                                )}
                                        </td>
                                        <td className='w-40 max-w-60 px-8 py-4 whitespace-nowrap text-center'>
                                            <Switch
                                                isSelected={Repraesentant}
                                                handleSelect={() =>
                                                    handleSetRepresentant({
                                                        portfolioItem,
                                                        portfolioItemId:
                                                        uuid,
                                                    })
                                                }
                                                isDistributor={company?.type === 'DISTRIBUTOR'}
                                            />
                                        </td>
                                        <td className='font-semibold text-xs px-8 py-4 whitespace-nowrap'>
                                            <Dropdown
                                                hoverText={Repraesentant ? t('cannot-change-status', {ns: 'portfolio'}) : ""}
                                                options={Repraesentant ? statusOptions.filter(option => option.value === true) : statusOptions}
                                                className={`h-6 !w-fit !rounded-full text-white ${active ? 'bg-primary-green/80' : 'bg-primary-red/80'}`}
                                                children={
                                                    <>
                                                        {active ?
                                                            <span>
                                                    {t(
                                                        'active',
                                                        {
                                                            ns: 'portfolio',
                                                        },
                                                    )}
                                                </span>
                                                            : <span>
                                                    {t(
                                                        'inactive',
                                                        {
                                                            ns: 'portfolio',
                                                        },
                                                    )}
                                                </span>
                                                        }
                                                        <ChevronDownIcon className='h-4 w-4 text-white'/>
                                                    </>
                                                }
                                                handleSelectedOption={(
                                                    value,
                                                ) =>
                                                    handleSetStatus({
                                                        value,
                                                        portfolioItemId: uuid,
                                                        isRepresentant: Repraesentant,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td colSpan={5}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pageInfo && (
                    <Paginator
                        pageInfo={pageInfo}
                        handleSetPerPage={handleResetPage}
                        handleSetCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        )
    }

    const handlePublishReferenceData = async () => {
        setIsPublishing(true)
        setIsShowPublishSpinnerModal(true)

        try {
            await publishReferenceData()
        } finally {
            setIsPublishing(false)
            setIsShowPublishSpinnerModal(false)
        }
    }

    return (
        <>
            <div className='flex flex-col w-full gap-4 text-primary-dark'>
                <Pill
                    className={"left-1/4 outline-semantic-warning bg-semantic-warning " + (isSupplierCompany ? "" : "hidden")}
                    label={t('supplier-warning1', {ns: 'portfolio'}) + t('supplier-warning2', {ns: 'portfolio'})}/>
                <div className='action-buttons flex justify-end space-x-2'>
                    {company?.type === 'SUPPLIER' && (
                        <Button
                            onClick={() => navigate('import')}
                            variant='contained'
                            label={t('import', {ns: 'portfolio'})}
                        />
                    )}
                    <Button
                        onClick={() => navigate('deploy/test')}
                        variant='contained'
                        label={t('test-portfolio', {ns: 'portfolio'})}
                    />
                    {company?.type === 'DISTRIBUTOR' ?
                        <Button
                            variant='contained'
                            onClick={() => navigate('deploy/prod')}
                            label={t('deploy', {ns: 'portfolio'})}
                        />
                        : <Button
                            variant='contained'
                            onClick={() =>
                                setIsShowPublishConfirmationModal(true)
                            }
                            label={t('publish', {ns: 'portfolio'})}
                        />
                    }
                    {/*<IconButton
onClick={rollback}
icon={<ArrowUturnLeftIcon className='h-4 w-4'/>}
label={t('rollback', {
ns: 'portfolio',
})}
className='text-white bg-primary-dark'
/>*/}
                </div>
                <ScrollableSliderTabs>
                    <ul className='flex gap-6'>
                        {filters.length > 1 &&
                            filters.map(({id, label, type}) => {
                                return (
                                    <li
                                        key={id}
                                        className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer  ${activeFilter === type && 'text-primary-dark border-b-2 border-primary-dark'} ${getCount(type) ?? 'hidden'}`}
                                        onClick={() => handleSelectTab(type)}
                                    >
                                        {label}
                                        <Pill
                                            className={`min-w-7 text-white ${activeFilter === type ? 'bg-primary-dark' : 'bg-neutral-700'}  ${getCount(type) == -1 ? 'hidden' : ""}`}
                                            label={getCount(type)}
                                        />
                                    </li>
                                )
                            })}
                    </ul>
                </ScrollableSliderTabs>
                <PortfolioItemTable/>
            </div>

            {isShowPublishConfirmationModal && (
                <Modal
                    label={t('publish-reference-data', {
                        ns: 'portfolio',
                    })}
                    content={
                        <span>
                            {t('confirm-publish-reference-data', {
                                ns: 'portfolio',
                            })}
                        </span>
                    }
                    handleCancel={() =>
                        setIsShowPublishConfirmationModal(false)
                    }
                    handleConfirm={handlePublishReferenceData}
                    confirmMessage={t('publish', {ns: 'portfolio'})}
                    cancelMessage={t('back', {ns: 'portfolio'})}
                />
            )}

            {isShowPublishSpinnerModal && (
                <LoadingCard
                    label={t('publishing', {ns: 'portfolio'})}
                    children={
                        <span className='text-center'>
                            {t('please-wait-while-the-reference-data-is-being-published', {ns: 'portfolio'})}
                        </span>
                    }
                />
            )}
        </>
    )
}
export default PortfolioTablePage
