import { z } from 'zod'

import instance from './axiosInstance'
import { PageInfo } from './portfolioApi'

export const companySchema = z.object({
    name: z.string().trim().min(1, { message: 'Required' }),
    type: z.enum(['SUPPLIER', 'DISTRIBUTOR']),
    productionInstanceUrl: z.string().url().optional().nullable(),
})

export type CompanySchema = z.infer<typeof companySchema>

export type Company = {
    uuid: string
    name: string
    type: 'DISTRIBUTOR' | 'SUPPLIER'
    status: string
    'production-instance-url': string
}

const CompanyApi = {
    create: async (params: CompanySchema) => {
        const { productionInstanceUrl, ...rest } = params

        const config = {
            url: '/organizations',
            method: 'POST',
            data: {
                ...rest,
                'production-instance-url': productionInstanceUrl,
                status: 'ACTIVE',
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to create company: ${error.message}`)
        }
    },

    get: async (params: { companyId: string }): Promise<Company> => {
        const { companyId } = params
        const config = {
            url: `/organizations/${companyId}`,
            method: 'GET',
            params,
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch company: ${error.message}`)
        }
    },

    getAll: async (params: {perPage?: number, page?: number}): Promise<{
        organizations: Company[]
        pageInfo: PageInfo
    }> => {
        const config = {
            url: '/organizations',
            method: 'GET',
            params
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(`Failed to fetch all companies: ${error.message}`)
        }
    },

    update: async (
        params: {
            companyId: string
        } & CompanySchema,
    ): Promise<Company> => {
        const { companyId, productionInstanceUrl, ...rest } = params

        const config = {
            url: `/organizations/${companyId}`,
            method: 'PUT',
            data: {
                ...rest,
                'production-instance-url': productionInstanceUrl,
                status: 'ACTIVE',
            },
        }

        try {
            const { data } = await instance.request(config)

            return data
        } catch (error: any) {
            throw new Error(`Failed to update organization: ${error.message}`)
        }
    },
}

export default CompanyApi
