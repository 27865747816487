import { t } from 'i18next'
import { Link } from 'react-router-dom'

import Logo from 'src/components/parts/Logo'
import Button from 'src/components/parts/Button'
import InputField from 'src/components/parts/InputField'

import { useHooks } from './hooks'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

const ForgotPasswordPage = () => {
    const {
        errors,
        passwordResetSuccess,
        navigate,
        register,
        onSubmit,
        handleSubmit,
    } = useHooks()

    const PasswordResetSuccessMessage = () => {
        return (
            <>
                <div className='flex flex-col gap-2 items-center'>
                    <span className='font-bold text-2xl'>
                        {t('success', { ns: 'auth' })}
                    </span>
                    <div className='text-neutral-900'>
                        <span>
                            {t('password-sent-to-email', { ns: 'auth' })}
                        </span>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <Button
                        onClick={() => navigate('/login')}
                        variant='contained'
                        color='primary-dark'
                        label={t('log-in', { ns: 'auth' })}
                    />
                </div>
            </>
        )
    }

    return (
        <div className='flex flex-col min-h-screen w-full p-10 items-center gap-12 bg-surface text-primary-dark'>
            <Logo />
            <div className='flex flex-col justify-centper items-start w-[500px] min-w-96 max-w-[500x] p-10 gap-12 border border-neutral-400 bg-neutral-200 rounded-lg'>
                {passwordResetSuccess ?
                    <PasswordResetSuccessMessage />
                :   <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='flex flex-col w-full gap-12'
                    >
                        <div className='flex flex-col items-center gap-2'>
                            <span className='font-bold text-2xl'>
                                {t('reset-password', { ns: 'auth' })}
                            </span>
                            <span className='text-sm text-neutral-900'>
                                {t('enter-email-to-receive-password', {
                                    ns: 'auth',
                                })}
                            </span>
                        </div>
                        <div className='flex flex-col gap-4'>
                            {errors?.email && (
                                <div className='flex items-center w-full rounded-[4px] gap-2 p-2 text-white bg-primary-red'>
                                    <ExclamationCircleIcon className='h-4 w-4 text-white' />
                                    <span>{errors.email.message}</span>
                                </div>
                            )}
                            <InputField
                                id='email'
                                register={register}
                                label={t('email-address', {
                                    ns: 'auth',
                                })}
                                placeholder={t('enter-email-address', {
                                    ns: 'auth',
                                })}
                            />
                            <Button
                                variant='contained'
                                color='primary-dark'
                                label={t('continue', { ns: 'auth' })}
                            />
                            <span className='text-sm text-neutral-900'>
                                {t('go-back-to', { ns: 'auth' })}{' '}
                                <Link
                                    to='/login'
                                    className='font-semibold hover:underline'
                                >
                                    {t('login-page', { ns: 'auth' })}
                                </Link>
                            </span>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

export default ForgotPasswordPage
