import {t} from 'i18next'

import Pill from 'src/components/parts/Pill'
import Modal from 'src/components/parts/Modal'
import Button from 'src/components/parts/Button'
import BackButton from 'src/components/parts/BackButton'
import InputRadio from 'src/components/parts/InputRadio'
import Paginator from 'src/components/templates/Paginator'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'

const PortfolioProdDeployment = () => {
    const {
        pageInfo,
        dateInputName,
        timeInputName,
        deploymentType,
        portfolioChanges,
        portfolioChangesData,
        isShowScheduledDeploymentModal,
        isShowImmediateDeploymentModal,
        isShowDeploymentSelectionModal,
        deploy,
        navigate,
        setCurrentPage,
        handleResetPage,
        deploymentSubmit,
        minimizeAndNotify,
        deploymentRegister,
        setIsShowScheduledDeploymentModal,
        setIsShowDeploymentSelectionModal,
    } = useHooks()

    const PortfolioChangesTable = () => {
        return (
            <div className='flex flex-col'>
                <div className='table-container max-h-[calc(100vh-360px)] overflow-y-auto'>
                    <table className='w-full text-sm text-left bg-neutral-50'>
                        <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('article-number', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='w-[480px] px-8 py-5 text-gray-700 whitespace-nowrap '
                            >
                                {t('name', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('changes', {
                                    ns: 'reference',
                                })}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {portfolioChanges.length > 0 ?
                            portfolioChanges.map(
                                (
                                    {
                                        Artikelnummer,
                                        Name,
                                        status,
                                        updatedFields,
                                        activeChange,
                                        repraesentantChange,
                                    },
                                    index,
                                ) => {
                                    return (
                                        <tr
                                            key={index}
                                            className='group border-neutral-200 border-b-[1px] hover:bg-white'
                                        >
                                            <td className='w-40 px-8 py-4 whitespace-nowrap'>
                                                {Artikelnummer}
                                            </td>
                                            <td className='flex min-96 w-max-[480px] gap-2 px-8 py-4'>
                                                    <span
                                                        className='whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                        {Name}
                                                    </span>
                                                {status && (
                                                    <Pill
                                                        label={status}
                                                        className='bg-primary-blue/50 text-white'
                                                    />
                                                )}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                {activeChange?.newValue &&
                                                    activeChange?.newValue !==
                                                    activeChange?.oldValue && (
                                                        <span className='flex gap-1'>
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}{' '}
                                                            <span className='font-semibold uppercase'>
                                                                    {t(
                                                                        'active',
                                                                        {
                                                                            ns: 'portfolio',
                                                                        },
                                                                    )}
                                                                </span>{' '}
                                                            {t(
                                                                'value-from',
                                                                {
                                                                    ns: 'portfolio',
                                                                },
                                                            )}
                                                            <Pill
                                                                label={`${activeChange.oldValue}`}
                                                                className='bg-gray-600/50 text-white w-fit'
                                                            />
                                                                to
                                                                <Pill
                                                                    label={`${activeChange.newValue}`}
                                                                    className='bg-primary-green/50 text-white w-fit'
                                                                />
                                                            </span>
                                                    )}

                                                {repraesentantChange?.newValue &&
                                                    repraesentantChange?.newValue !==
                                                    repraesentantChange?.oldValue && (
                                                        <span className='flex gap-1 my-1'>
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}
                                                            <span className='font-semibold uppercase'>
                                                                    {t(
                                                                        'representant',
                                                                        {
                                                                            ns: 'portfolio',
                                                                        },
                                                                    )}
                                                                </span>{' '}
                                                            {t(
                                                                'value-from',
                                                                {
                                                                    ns: 'portfolio',
                                                                },
                                                            )}
                                                            <Pill
                                                                label={`${repraesentantChange.oldValue}`}
                                                                className='bg-gray-600/50 text-white w-fit'
                                                            />
                                                                to
                                                                <Pill
                                                                    label={`${repraesentantChange.newValue}`}
                                                                    className='bg-primary-green/50 text-white w-fit'
                                                                />
                                                            </span>
                                                    )}

                                                {updatedFields.map(
                                                    (field, index) => (
                                                        <span
                                                            key={index}
                                                            className='flex gap-1'
                                                        >
                                                                {t('updated', {
                                                                    ns: 'portfolio',
                                                                })}
                                                            <span className='font-semibold uppercase'>
                                                                    {field}
                                                                </span>{' '}
                                                            value
                                                            </span>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    )
                                },
                            )
                            : <tr>
                                <td colSpan={3}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pageInfo && (
                    <Paginator
                        pageInfo={pageInfo}
                        handleSetPerPage={handleResetPage}
                        handleSetCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        )
    }

    return (
        <>
            <div className='flex flex-col w-full gap-4 text-primary-dark'>
                <div className='flex items-center gap-4'>
                    <BackButton/>
                    <span className='font-bold text-2xl'>{t('deploy', {ns: 'portfolio'})}</span>
                </div>
                <div className='flex gap-2 text-sm font-semibold text-primary-dark'>
                    <span>{t('overview-of-changes', {ns: 'portfolio'})}</span>
                    <Pill
                        className='min-w-7 text-primary-dark bg-neutral-400'
                        label={portfolioChangesData?.pagingInfo.totalCount}
                    />
                </div>
                <PortfolioChangesTable/>
                <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                    <Button
                        onClick={() => setIsShowDeploymentSelectionModal(true)}
                        variant='contained'
                        color='primary-dark'
                        label={t('deploy', {ns: 'portfolio'})}
                    />
                </div>
            </div>

            {isShowDeploymentSelectionModal && (
                <Modal
                    label={t('deploy', {ns: 'portfolio'})}
                    content={
                        <div className='flex flex-col w-96 gap-8'>
                            <div className='flex flex-col gap-2 text-primary-dark'>
                                <span>{t('deployNowOrLater', {ns: 'portfolio'})}</span>
                                {[
                                    {
                                        id: 1,
                                        label: t('deployNow', {ns: 'portfolio'}),
                                        value: 'deploy-now',
                                    },
                                    {
                                        id: 2,
                                        label: t('deployLater', {ns: 'portfolio'}),
                                        value: 'deploy-later',
                                    },
                                ].map(({id, label, value}) => {
                                    return (
                                        <InputRadio
                                            key={id}
                                            id={label}
                                            register={deploymentRegister}
                                            name='deploymentType'
                                            label={label}
                                            value={value}
                                            className='text-sm'
                                        />
                                    )
                                })}
                                {deploymentType === 'deploy-later' && (
                                    <>
                                        <input
                                            type='date'
                                            {...deploymentRegister(
                                                'dateInputName',
                                            )}
                                            name='dateInputName'
                                            className='h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px]'
                                            min={
                                                new Date()
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                        />
                                        <input
                                            type='time'
                                            {...deploymentRegister(
                                                'timeInputName',
                                            )}
                                            name='timeInputName'
                                            className='h-10 w-full py-[14px] px-3 border border-1 border-neutral-300 rounded-[4px]'
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    handleCancel={() =>
                        setIsShowDeploymentSelectionModal(false)
                    }
                    handleConfirm={deploymentSubmit(deploy)}
                    confirmMessage='Deploy'
                    cancelMessage='Back'
                />
            )}

            {isShowImmediateDeploymentModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50 z-50'>
                    <div className='absolute flex flex-col items-center max-w-96 gap-6 p-5 rounded-lg bg-white'>
                        <span className='text-base font-bold '>
                            Deploying to Production {/* TODO */}
                        </span>
                        <LoadingSpinner classname='!h-10 !w-10'/>
                        <span className='text-center'>
                            This will only take a moment. We will notify you as
                            soon as possible.
                        </span>
                        <div className='flex w-fit'>
                            <Button
                                onClick={minimizeAndNotify}
                                variant='contained'
                                color='primary-dark'
                                label='Minimize and Notify Me'
                            />
                        </div>
                    </div>
                </div>
            )}

            {isShowScheduledDeploymentModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50 z-50'>
                    <div className='absolute flex flex-col max-w-96 gap-6 p-5 rounded-lg bg-white'>
                        <span className='text-base font-bold '>
                            Deployment Schedule
                        </span>
                        <div>
                            We are scheduling the deployment on{' '}
                            <span className='font-bold'>{dateInputName}</span>{' '}
                            at
                            <span className='font-bold'> {timeInputName}</span>
                        </div>
                        <div className='flex justify-end w-full'>
                            <Button
                                onClick={() => {
                                    setIsShowScheduledDeploymentModal(false)
                                    navigate('/deployment/production')
                                }}
                                variant='contained'
                                color='primary-dark'
                                label='OK'
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default PortfolioProdDeployment
