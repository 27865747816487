import { BrowserRouter } from 'react-router-dom'

import Layout from 'src/components/layout'

import AppRoutes from 'src/routes'
import { AxiosInterceptor } from './services/api/axiosInstance'

function App() {
    return (
        <BrowserRouter>
            <AxiosInterceptor>
                <Layout>
                    <AppRoutes />
                </Layout>
            </AxiosInterceptor>
        </BrowserRouter>
    )
}

export default App
