// eslint-disable-next-line import/no-anonymous-default-export
export default {
    company: 'Company',
    cancel: 'Cancel',
    'change-password': 'change password',
    deployment: 'Deployment',
    details: 'Details',
    edit: 'Edit',
    english: 'English',
    'email-address': 'Email address',
    german: 'German',
    name: 'Name',
    no: 'No',
    'no-details-to-show': 'There is no data to display',
    'please-enter-your-details-to-login': 'Please enter your details to login',
    portfolio: 'Portfolio',
    'page-not-found': 'Page not found',
    'passwords-do-not-match': 'Passwords do not match',
    unauthorized: 'unauthorized',
    'unauthorized-message':
        ' You do not have the necessary permissions to view this page or access this resource. Please contact your administrator for assistance.',
    settings: 'Settings',
    'welcome-to-mms': 'Welcome back to MMS',
    save: 'Save',
    yes: 'Yes',
}
