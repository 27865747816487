import {ReactNode, useState} from 'react'

type DetailProps = {
    label?: string
    value?: string | ReactNode
    classname?: string
}

const Detail = ({label, value, classname}: DetailProps) => {

    const [isHovered, setIsHovered] = useState<boolean>(false)
    const maxLength = 24
    if (typeof label === 'undefined') {
        label = ""
    }
    let shortLabel = label.length > maxLength ? label.substring(0, maxLength - 3) + "..." : label;

    return <>
        <div className={`flex flex-col text-sm w-60 gap-2 ${classname}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
            <span className={"font-semibold uppercase " + (isHovered ? "text-black z-50" : "text-neutral-400")}>
                {isHovered ? label : shortLabel}
            </span>
            <span className='text-neutral-900 font-semibold'>
                {value || '-'}
            </span>
        </div>
    </>
}
export default Detail
