import {z} from 'zod'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useLocation, useNavigate} from 'react-router-dom'

import {useAuthApi} from 'src/services/hooks/useAuthApi'

const passwordValidationSchema = z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .regex(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
    .regex(/[a-z]/, 'Password must contain at least 1 lowercase letter')
    .regex(/[0-9]/, 'Password must contain at least 1 number')
    .regex(
        /[!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]/,
        'Password must contain at least 1 symbol',
    )

const passwordSetupSchema = z.object({
    newPassword: passwordValidationSchema,
    passwordConfirm: z.string(),
})

type PasswordSetupParams = z.infer<typeof passwordSetupSchema>

const initialFormState = {
    newPassword: '',
    passwordConfirm: '',
}

export const useHooks = () => {
    const navigate = useNavigate()
    const {loginAsNewUser} = useAuthApi()
    const {search} = useLocation()
    const params = new URLSearchParams(search)

    // @ts-ignore
    const email = params.get('email') !== null ? params.get('email').toLowerCase() : params.get('email')
    const password = params.get('password')

    const [passwordSetupSuccess, setPasswordSetupSuccess] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        setError,
    } = useForm<PasswordSetupParams>({
        resolver: zodResolver(passwordSetupSchema),
    })

    const onSubmit = async ({
                                newPassword,
                                passwordConfirm,
                            }: PasswordSetupParams) => {
        if (newPassword !== passwordConfirm) {
            return setError('newPassword', {
                message: 'Passwords do not match',
            })
        }

        try {
            if (email && password) {
                await loginAsNewUser({email, password, newPassword})
                setPasswordSetupSuccess(true)
                reset(initialFormState)
            } else {
                navigate('/login')
                throw new Error('Invalid credentials')
            }
        } catch (e: any) {
            return setError('newPassword', {
                message: e.message,
            })
        }
    }

    return {
        errors,
        passwordSetupSuccess,
        register,
        navigate,
        onSubmit,
        handleSubmit,
    }
}
