import Logo from 'src/components/parts/Logo'
import { VerticalDivider } from 'src/components/parts/VerticalDivider'

import LanguageDropdown from './language-dropdown'
import ProfileDropdown from './profile-dropdown'
import Navbar from './navbar'

const Header = () => {
    return (
        <div className='flex justify-between w-full h-full  py-5 px-9 bg-white'>
            <div className='flex items-center w-full gap-20'>
                <Logo />
                <Navbar />
            </div>
            <div className='flex h-full items-center gap-8'>
                <LanguageDropdown />
                <div className='flex h-full items-center gap-6'>
                    <VerticalDivider className='border-neutral-400' />
                    <ProfileDropdown />
                </div>
            </div>
        </div>
    )
}

export default Header
