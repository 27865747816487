import toast from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import ReferenceDataApi from 'src/services/api/referenceDataApi'

type ReferenceDataQueryProps = {
    companyId?: string
    perPage?: number
    page?: number
    enabled?: boolean
}

export const useReferenceDataQuery = ({
    companyId,
    perPage,
    page,
    enabled
}: ReferenceDataQueryProps) => {
    const { upload, getAllVersions } = ReferenceDataApi

    const queryClient = useQueryClient()

    const {
        mutateAsync: uploadReferenceData,
        isLoading: isLoadingUploadReferenceData,
        isError: isUploadReferenceDataError,
    } = useMutation(upload, {
        onSuccess: () => {
            queryClient.invalidateQueries(['portfolioData', companyId])
        },
    })

    const {
        data: referenceDataVersionData,
        isLoading: isLoadingReferenceDataVersions,
        refetch: refetchReferenceDataVersionData
    } = useQuery({
        queryKey: 'referenceDataVersions',
        queryFn: () => (companyId ? getAllVersions({ companyId, perPage, page }) : undefined),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
        enabled
    })

    return {
        referenceDataVersionData,
        isLoadingUploadReferenceData,
        isUploadReferenceDataError,
        isLoadingReferenceDataVersions,
        uploadReferenceData,
        refetchReferenceDataVersionData,
    }
}
