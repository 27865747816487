import { useParams } from 'react-router-dom'

import { useAuthContext } from 'src/context/AuthProvider'
import { useCompanyQuery } from 'src/services/hooks/useCompanyQuery'

export const useHooks = () => {
    const { isAdmin } = useAuthContext()
    const { companyId, page } = useParams()
    const { company, isLoadingCompany } = useCompanyQuery({ companyId })

    return {
        page,
        isAdmin,
        company,
        isLoadingCompany,
    }
}
