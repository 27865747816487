// eslint-disable-next-line import/no-anonymous-default-export

export default {
    'article-number': 'article number',
    'article-type': 'article type',
    active: 'active',
    inactive: 'inactive',
    available: 'available',
    back: 'Back',
    availability: 'availability',
    all: 'All',
    browse: 'browse',
    'biber-tail': 'Biber tail',
    cancel: 'Cancel',
    'confirm-publish-reference-data':
        'Are you sure you want to publish the reference data?',
    'cannot-upload-multiple-files': 'Cannot upload multiple files.',
    'creating-test-instance': 'Creating Test Instance',
    'create-test-instance': 'Create Test Instance',
    changes: 'Changes',
    deploy: 'Deploy Portfolio',
    'drag-and-drop': 'Drag and drop',
    'drop-file-to-upload': 'Drop file to upload',
    error: 'Error',
    'file-to-upload': 'file to upload',
    'import-reference-data': 'Import reference data',
    import: 'Import',
    'in-progress': 'In progress...',
    locked: 'locked',
    'minimize-and-notify': 'Minimize and Notify Me',
    'main-details': 'details',
    manufacturer: 'Manufacturer',
    'must-select-file': 'Must select a file',
    name: 'Name',
    'overview-of-changes': 'Overview of changes',
    'please-wait-while-the-reference-data-is-being-validated':
        'Please wait while the reference data is being validated',
    publish: 'Publish',
    'process-notification':
        'This may take up to 20 minutes to finish. We will notify you as soon as possible.',
    'publish-reference-data': 'Publish Reference Data',
    rollback: 'Rollback',
    'roll-back-my-changes': 'Roll back my changes',
    representative: 'representative',
    representant: 'representant',
    rails: 'Rails',
    'successfully-imported-reference-data':
        'Successfully imported the reference data',
    saved: 'Saved',
    status: 'status',
    'secondary-details': 'secondary details',
    updated: 'Updated',
    test: 'Test',
    'test-portfolio': 'Test Portfolio',
    upload: 'Upload',
    unavailable: 'unavailable',
    'value-from': 'value from',
    'view-details': 'View details',
    validating: 'Validating',
    value: 'value',
    'supplier-warning1': "The changes you can make here are intended for testing purposes only and will not be published.",
    'supplier-warning2': "Reference data can only be changed via an upload.",
    "cannot-change-status": "Articles that are representative cannot be deactivated",
    "please-wait-while-the-reference-data-is-being-published": "Please wait while the reference data is being published.",
    publishing: "Publishing",
    "test-instance": "Test Instances",
    deployNowOrLater: "Deploy the portfolio now or schedule the deployment?",
    deployNow: "Now",
    deployLater: "Later",
}
