import { useTranslation } from 'react-i18next'
import { GlobeAltIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import Dropdown from 'src/components/parts/Dropdown'

const languageOptions = [
    { id: 'de', label: 'Deutsch', value: 'de' },
    { id: 'en', label: 'English', value: 'en' },
]

const LanguageDropdown = () => {
    const { i18n } = useTranslation()

    const selectedLanguageId = localStorage.getItem('i18nextLng')

    const selectedLanguageOption = languageOptions.find(
        (option) => option.id === selectedLanguageId,
    )

    const handleChangeLanguage = (value: string) => {
        i18n.changeLanguage(value)
        window.location.reload()
    }

    return (
        <Dropdown
            options={languageOptions}
            handleSelectedOption={handleChangeLanguage}
            className='bg-neutral-200'
        >
            <div className='flex gap-1.5'>
                <GlobeAltIcon className='h-6 w-6 text-neutral-700' />
                <span className='font-semibold'>
                    {selectedLanguageOption?.label}
                </span>
            </div>
            <ChevronDownIcon className='h-4 w-4 text-neutral-700' />
        </Dropdown>
    )
}

export default LanguageDropdown
