import { t } from 'i18next'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PhotoIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/parts/Button'
import { toTitleCase } from 'src/utils/toTitleCase'
import InputField from 'src/components/parts/InputField'
import { useAuthContext } from 'src/context/AuthProvider'
import DisplayField from 'src/components/parts/DisplayField'
import {
    Company,
    CompanySchema,
    companySchema,
} from 'src/services/api/companyApi'
import { useCompanyQuery } from 'src/services/hooks/useCompanyQuery'

const CompanyDetails = ({ company }: { company: Company }) => {
    const { uuid: companyId } = company
    const { isAdmin } = useAuthContext()
    const { updateCompany, isLoadingUpdateCompany } = useCompanyQuery({
        companyId,
    })

    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const { register, control, handleSubmit } = useForm<CompanySchema>({
        resolver: zodResolver(companySchema),
        defaultValues: {
            name: company.name,
            productionInstanceUrl: company['production-instance-url'],
            type: company.type,
        },
    })

    const { type, name, productionInstanceUrl } = useWatch({ control })

    const handleEditModeToggle = () => {
        setIsEditMode(!isEditMode)
    }

    const onSubmit = async (data: CompanySchema) => {
        const { productionInstanceUrl, ...supplierData } = data

        try {
            if (type === 'DISTRIBUTOR') {
                await updateCompany({ companyId, ...data })
            } else {
                await updateCompany({ companyId, ...supplierData })
            }
            !isLoadingUpdateCompany && setIsEditMode(!isEditMode)
        } catch (error: any) {
            toast.error(error.message)
        }
    }

    return (
        <>
            <div className={`absolute right-0 top-0 ${isAdmin && 'mr-10'}`}>
                <Button
                    onClick={
                        isEditMode ?
                            handleSubmit(onSubmit)
                        :   handleEditModeToggle
                    }
                    variant='contained'
                    color='primary-dark'
                    label={
                        isEditMode ?
                            t('save-changes', { ns: 'company' })
                        :   t('edit-details', { ns: 'company' })
                    }
                />
            </div>
            <div className='flex space-x-8'>
                <div className='flex flex-col space-y-[10px]'>
                    <div className='flex justify-center items-center w-56 h-56 rounded-lg border-primary-dark bg-neutral-300'>
                        <PhotoIcon className='w-10 h-10 text-neutral-400' />
                    </div>
                    {/*
                    <button
                        className={`text-start font-semibold text-sm text-neutral-700 ${isEditMode === false && 'hidden'}`}
                    >
                        {t('add-image', { ns: 'company' }).toUpperCase()}
                    </button>
                    */}
                </div>
                <div className='flex flex-col w-full gap-5'>
                    {isEditMode ?
                        <>
                            {/* TODO: display when updating of company type is enabled */}
                            {/* <div className='flex gap-5'>
                                {[
                                    {
                                        id: 1,
                                        label: 'distributor',
                                        value: 'DISTRIBUTOR',
                                    },
                                    {
                                        id: 2,
                                        label: 'supplier',
                                        value: 'SUPPLIER',
                                    },
                                ].map(({ id, label, value }) => {
                                    return (
                                        <InputRadio
                                            key={id}
                                            id={label}
                                            name='type'
                                            register={register}
                                            label={label}
                                            value={value}
                                            className='font-semibold text-sm text-neutral-700'
                                        />
                                    )
                                })}
                            </div> */}
                            <InputField
                                id='name'
                                register={register}
                                placeholder={t('enter-name', { ns: 'company' })}
                                label={t('name')}
                            />
                            <InputField
                                id='productionInstanceUrl'
                                register={register}
                                placeholder={t(
                                    'enter-production-instance-url',
                                    {
                                        ns: 'company',
                                    },
                                )}
                                label={t('production-instance-url', {
                                    ns: 'company',
                                })}
                                disabled={type !== 'DISTRIBUTOR'}
                            />
                        </>
                    :   <>
                            <DisplayField label='name' value={name} />
                            <DisplayField
                                label={t('category', {
                                    ns: 'company',
                                })}
                                value={type && toTitleCase(type)}
                            />

                            {productionInstanceUrl && (
                                <DisplayField
                                    label={t('production-instance-url', {
                                        ns: 'company',
                                    })}
                                    value={productionInstanceUrl}
                                />
                            )}
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default CompanyDetails
