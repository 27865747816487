import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

import DeploymentApi from 'src/services/api/deploymentApi'

type ProdDeploymentQueryProps = {
    deploymentId?: string
    companyId?: string
}

export const useProdDeploymentQuery = ({
    companyId,
    deploymentId,
}: ProdDeploymentQueryProps) => {
    const { getProdDeployment } = DeploymentApi

    const { data: prodDeployment, isLoading: isLoadingProdDeployment } = useQuery({
        queryKey: 'prodDeployment',
        queryFn: () => companyId && deploymentId ? getProdDeployment({ companyId, deploymentId }) : undefined,
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
    })

    return {
        prodDeployment,
        isLoadingProdDeployment
    }
}
