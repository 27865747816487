import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Company } from 'src/services/api/companyApi'
import { useAuthContext } from 'src/context/AuthProvider'
import { useCompanyQuery } from 'src/services/hooks/useCompanyQuery'

type Label = 'all' | 'distributors' | 'suppliers'
type Type = 'DISTRIBUTOR' | 'SUPPLIER'

type Filter = {
    id: number
    label: Label
    type?: Type
}[]

const filters: Filter = [
    { id: 1, label: 'all', type: undefined },
    { id: 2, label: 'distributors', type: 'DISTRIBUTOR' },
    { id: 3, label: 'suppliers', type: 'SUPPLIER' },
]

export const useHooks = () => {
    const navigate = useNavigate()
    const { isAdmin, company } = useAuthContext()
    const { companyData, isLoadingCompanies } = useCompanyQuery({})

    const companies = useMemo(() => {
        return companyData?.organizations || []
    }, [companyData?.organizations])

    const [activeFilter, setActiveFilter] = useState<any>(filters[0].type)

    const filterByType = (type?: string): Company[] => {
        const filteredCompanies =
            type ?
                companies?.filter((company: Company) => company.type === type)
            :   companies
        return filteredCompanies
    }
    const filteredCompanies = filterByType(activeFilter)

    const getCount = (type?: string) => {
        if (type) {
            return filterByType(type)?.length
        } else {
            return companies?.length
        }
    }

    const handleSelectTab = (type?: string) => {
        setActiveFilter(type)
    }

    const handleAddCompany = () => {
        navigate('/company/new')
    }

    useEffect(() => {
        if (!isAdmin && company) {
            navigate(`/company/${company.uuid}/details`)
        }
    }, [isAdmin, company, navigate])

    return {
        isAdmin,
        filters,
        activeFilter,
        filteredCompanies,
        isLoadingCompanies,
        getCount,
        handleSelectTab,
        handleAddCompany,
    }
}
