import { t } from 'i18next'
import { Link, useParams } from 'react-router-dom'

import Button from 'src/components/parts/Button'
import { toTitleCase } from 'src/utils/toTitleCase'
import BackButton from 'src/components/parts/BackButton'

import { useHooks } from './hooks'
import ProfileDetails from './details'
import ProfileChangePassword from './change-password'

type Tabs = {
    id: number
    label: string
    value: 'details' | 'change-password'
}[]

const tabs: Tabs = [
    { id: 1, label: 'details', value: 'details' },
    { id: 2, label: 'change-password', value: 'change-password' },
]

const ProfilePage = () => {
    const { page } = useParams()
    const { isEditMode, setIsEditMode, handleEditModeToggle } = useHooks()

    return (
        <div className={`flex flex-col gap-4`}>
            <div className='flex items-center gap-4'>
                <BackButton href='/portfolio' />
                <span className='font-bold text-2xl'>
                    {t('profile', { ns: 'company' })}
                </span>
            </div>
            <div className='relative flex gap-6'>
                {tabs.map(({ label, value }, index) => {
                    return (
                        <Link
                            to={`/profile/${value}`}
                            key={index}
                            className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer 
                                ${page === value && 'text-primary-dark border-b-2 border-primary-dark'}
                                `}
                        >
                            {toTitleCase(t(`${label}`, {ns: 'common'}))}
                        </Link>
                    )
                })}
                {page === 'details' && !isEditMode && (
                    <div className={`absolute right-0 top-0`}>
                        <Button
                            onClick={handleEditModeToggle}
                            variant='contained'
                            color='primary-dark'
                            label={t('edit-details', { ns: 'company' })}
                        />
                    </div>
                )}
            </div>
            {page === 'details' ?
                <ProfileDetails
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                />
            :   <ProfileChangePassword />}
        </div>
    )
}

export default ProfilePage
