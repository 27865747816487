import { t } from 'i18next'
import toast from 'react-hot-toast'
import { useState, DragEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuthContext } from 'src/context/AuthProvider'
import { useReferenceDataQuery } from 'src/services/hooks/useReferenceDataQuery'

export const useHooks = () => {
    const navigate = useNavigate()
    const { auth } = useAuthContext()
    const companyId = auth?.user?.organizationId

    const { uploadReferenceData } = useReferenceDataQuery({})

    const [isOver, setIsOver] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [isValidating, setIsValidating] = useState<boolean>(false)
    const [validationErrors, setValidationErrors] = useState<any>()

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsOver(true)
    }

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsOver(false)
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsOver(false)

        const droppedFiles = Array.from(event.dataTransfer.files)

        if (droppedFiles.length > 1) {
            toast.error(
                `${t('cannot-upload-multiple-files', { ns: 'portfolio' })}`,
            )
        } else {
            setFile(droppedFiles[0])
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files

        if (!selectedFiles) {
            return toast.error(`${t('must-select-file', { ns: 'portfolio' })}`)
        }

        if (selectedFiles.length > 1) {
            toast.error(
                `${t('cannot-upload-multiple-files', { ns: 'portfolio' })}`,
            )
        } else {
            setFile(selectedFiles[0])
        }
    }

    const handleImport = async () => {
        setIsValidating(true)

        if (file && companyId) {
            setTimeout(async () => {
                try {
                    const { message, validationMessages } =
                        await uploadReferenceData({ file, companyId })
                    if (message === 'SUCCESS') {
                        toast.success(
                            `${t('successfully-imported-reference-data', { ns: 'portfolio' })}`,
                        )
                        navigate('/portfolio')
                    } else {
                        setValidationErrors(validationMessages)
                    }
                } catch (error: any) {
                    toast.error(error.message)
                }
                setIsValidating(false)
            }, 3 * 1000)
        }
    }

    const handleBack = () => {
        navigate(-1)
    }

    const handleRemoveFile = () => {
        setFile(null)
    }

    const handleCloseModal = () => {
        setValidationErrors(null)
    }
    return {
        file,
        isOver,
        isValidating,
        validationErrors,
        handleBack,
        handleDrop,
        handleImport,
        handleDragOver,
        handleDragLeave,
        handleFileChange,
        handleRemoveFile,
        handleCloseModal,
    }
}
