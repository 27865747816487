import {t} from 'i18next'

import Pill from 'src/components/parts/Pill'
import Button from 'src/components/parts/Button'
import BackButton from 'src/components/parts/BackButton'
import Paginator from 'src/components/templates/Paginator'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'

import {useHooks} from './hooks'

const PortfolioTestDeployment = () => {
    const {
        pageInfo,
        portfolioChanges,
        portfolioChangesData,
        isShowCreateInstanceModal,
        isLoadingPortfolioChangesData,
        setCurrentPage,
        handleResetPage,
        minimizeAndNotify,
        deployToTestInstance,
    } = useHooks()

    const PortfolioChangesTable = () => {
        return (
            <div className='flex flex-col'>
                <div className='table-container max-h-[calc(100vh-360px)] overflow-y-auto'>
                    <table className='w-full text-sm text-left bg-neutral-50'>
                        <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                        <tr>
                            <th
                                scope='col'
                                className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('article-number', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='w-[480px] px-8 py-5 text-gray-700 whitespace-nowrap '
                            >
                                {t('name', {ns: 'portfolio'})}
                            </th>
                            <th
                                scope='col'
                                className='px-8 py-5 text-gray-700 whitespace-nowrap'
                            >
                                {t('changes', {
                                    ns: 'reference',
                                })}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {portfolioChanges.length > 0 ?
                            portfolioChanges.map(
                                (
                                    {
                                        Artikelnummer,
                                        Name,
                                        status,
                                        updatedFields,
                                    },
                                    index,
                                ) => {
                                    return (
                                        <tr
                                            key={index}
                                            className='group border-neutral-200 border-b-[1px] hover:bg-white'
                                        >
                                            <td className='w-40 px-8 py-4 whitespace-nowrap'>
                                                {Artikelnummer}
                                            </td>
                                            <td className='flex min-96 w-max-[480px] gap-2 px-8 py-4'>
                                                    <span
                                                        className='whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                        {Name}
                                                    </span>
                                                {status && (
                                                    <Pill
                                                        label={status}
                                                        className='bg-primary-green/50 text-white'
                                                    />
                                                )}
                                            </td>
                                            <td className='px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                {updatedFields.map(
                                                    (field, index) => {
                                                        return (
                                                            <span key={index}>
                                                                <span className='font-semibold'>
                                                                    {field}
                                                                </span>
                                                            </span>
                                                        )
                                                    },
                                                )}
                                            </td>
                                        </tr>
                                    )
                                },
                            )
                            : <tr>
                                <td colSpan={3}>
                                    <NoDetailsToShow/>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pageInfo && (
                    <Paginator
                        pageInfo={pageInfo}
                        handleSetPerPage={handleResetPage}
                        handleSetCurrentPage={setCurrentPage}
                    />
                )}
            </div>
        )
    }

    if (isLoadingPortfolioChangesData) {
        return <LoadingSpinner/>
    }

    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            <div className='flex items-center gap-4'>
                <BackButton/>
                <span className='font-bold text-2xl'>
                    {t('test', {ns: 'portfolio'})}
                </span>
            </div>
            <div className='flex gap-2 text-sm font-semibold text-primary-dark'>
                <span> {t('overview-of-changes', {ns: 'portfolio'})}</span>
                <Pill
                    className='min-w-7 text-primary-dark bg-neutral-400'
                    label={portfolioChangesData?.pagingInfo.totalCount}
                />
            </div>
            <PortfolioChangesTable/>
            <div className='fixed bottom-0 right-0 flex justify-end w-full gap-2 p-6 bg-white'>
                <Button
                    onClick={deployToTestInstance}
                    variant='contained'
                    color='primary-dark'
                    label={t('create-test-instance', {ns: 'portfolio'})}
                />
            </div>
            {isShowCreateInstanceModal && (
                <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50'>
                    <div className='absolute flex flex-col items-center max-w-96 gap-6 p-5 rounded-lg bg-white z-50'>
                        <span className='text-base font-bold '>
                            {t('creating-test-instance', {ns: 'portfolio'})}
                        </span>
                        <LoadingSpinner classname='!h-10 !w-10'/>
                        <span className='text-center'>
                            {t('process-notification', {ns: 'portfolio'})}
                        </span>
                        <div className='flex w-fit'>
                            <Button
                                onClick={minimizeAndNotify}
                                variant='contained'
                                color='primary-dark'
                                label={t('minimize-and-notify', {
                                    ns: 'portfolio',
                                })}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PortfolioTestDeployment
