import toast from 'react-hot-toast'
import { useQuery } from 'react-query'

import DeploymentApi from 'src/services/api/deploymentApi'

type ProdDeploymentsQueryProps = {
    companyId?: string
    enabled: boolean
}

export const useProdDeploymentsQuery = ({
    companyId,
    enabled
}: ProdDeploymentsQueryProps) => {
    const { getAllProdDeployments } = DeploymentApi

    const { data: prodDeployments, isLoading: isLoadingProdDeployments } = useQuery({
        queryKey: 'prodDeployments',
        queryFn: () => (companyId ? getAllProdDeployments({ companyId }) : undefined),
        onError: (error: any) => {
            toast.error(error.message)
        },
        retry: 2,
        enabled,
    })

    return {
        prodDeployments,
        isLoadingProdDeployments,
    }
}
