// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'article-number': 'article number',
    action: 'action',
    'deployment-date': 'deployment date',
    environment: 'environment',
    error: 'error',
    errors: 'errors',
    'import-errors-found': 'There were import errors found.',
    'instance-url': 'instance url',
    message: 'message',
    'requested-by': 'requested by',
    status: 'status',
    'scheduled-date': 'scheduled date',
    version: 'version',
    success: 'Success',
    failed: 'Deployment failed',
    "status-success": "success",
    "status-failed": "failed",
    "status-failure": "failed",
    "status-pending": "pending",
    "status-scheduled": "scheduled",
    viewErrors: "View Errors",
    cancel: "cancel",
}
