import {t} from 'i18next'
import moment from 'moment-timezone'
import {Link} from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import {useAuthContext} from 'src/context/AuthProvider'
import {getPillStatusColor} from 'src/utils/getPillStatusColor'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import {useTestDeploymentQuery} from 'src/services/hooks/useTestDeploymentQuery'
import Button from "../../../components/parts/Button";
import {useHooks} from "../prod-deployments/hooks.tsx";

export const TestDeploymentsTable = () => {
    const {company} = useAuthContext()
    const companyId = company?.uuid

    const {testDeployments, isLoadingTestDeployments} =
        useTestDeploymentQuery({
            companyId,
        })
    const {
        handleDeploymentError,
    } = useHooks()
    if (isLoadingTestDeployments) {
        return <LoadingSpinner/>
    }

    return (
        <div className='table-container max-h-[calc(100vh-240px)] overflow-y-auto'>
            <table className='w-full text-sm text-left bg-neutral-50'>
                <thead className='sticky top-0 text-sm uppercase bg-neutral-200'>
                <tr>
                    <th
                        scope='col'
                        className='w-40 min-w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('deployment-date', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='w-96 max-w-96 min-w-96 px-8 py-5 text-gray-700 whitespace-nowrap '
                    >
                        {t('instance-url', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='w-40 px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('requested-by', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('status', {ns: 'deployment'})}
                    </th>
                    <th
                        scope='col'
                        className='px-8 py-5 text-gray-700 whitespace-nowrap'
                    >
                        {t('errors', {ns: 'deployment'})}
                    </th>
                </tr>
                </thead>
                <tbody>
                {testDeployments && testDeployments?.length > 0 ?
                    testDeployments.map(
                        ({
                             deploymentUuid,
                             url,
                             deploymentStatus,
                             deploymentDate,
                             requestedBy,
                             message,
                         }) => (
                            <tr
                                key={deploymentUuid}
                                className='group border-neutral-200 border-b-[1px] hover:bg-white'
                            >
                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap'>
                                    {moment(deploymentDate).format(
                                        'DD.MM.YYYY, h:mm A',
                                    )}
                                </td>
                                <td className='w-96 max-w-96 px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis '>
                                    {url !== 'null' ?
                                        <Link target="SOLARPLANIT"
                                              to={`${url}`}
                                              className='text-info group-hover:underline'
                                        >
                                            {url}
                                        </Link>
                                        : '-'}
                                </td>
                                <td className='w-40 max-w-40 px-8 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                    {requestedBy ?? '-'}
                                </td>
                                <td className='px-8 py-4 whitespace-nowrap'>
                                    <Pill
                                        className={`w-fit h-6 text-white ${getPillStatusColor(deploymentStatus)}`}
                                        label={t('status-' + deploymentStatus.toLowerCase(), {ns: 'deployment'})}
                                    />
                                </td>
                                <td className='px-8 py-4 whitespace-nowrap'>
                                    {message && (
                                        <Button
                                            label= {t('viewErrors', { ns: 'deployment' })}
                                            variant='outlined'
                                            onClick={() =>
                                                handleDeploymentError(
                                                    deploymentUuid,
                                                    "test-instance"
                                                )
                                            }
                                            className='py-[1px] px-[10px] text-xs !h-6 border-primary-red text-primary-red'
                                        />
                                    )}
                                </td>
                            </tr>
                        ),
                    )
                    : <tr>
                        <td colSpan={6}>
                            <NoDetailsToShow/>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}
