import { ReactNode } from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

const LoadingCard = ({
    label,
    children,
}: {
    label: string
    children: string | ReactNode
}) => {
    return (
        <div className='fixed inset-0 flex items-center justify-center h-screen w-full bg-primary-dark/50'>
            <div className='absolute flex flex-col items-center max-w-80 gap-6 p-5 rounded-lg bg-white z-50'>
                <span className='text-base font-bold'>{label}</span>
                <ArrowPathIcon className='animate-spin h-10 w-10' />
                {children}
            </div>
        </div>
    )
}
export default LoadingCard
