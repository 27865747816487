import {z} from 'zod'

import {Role} from './roleApi'
import instance from './axiosInstance'
import {PageInfo} from './portfolioApi'

export const userSchema = z.object({
    email: z.string().email(),
    firstname: z.string().trim().min(1, {message: 'Required'}),
    lastname: z.string().trim().min(1, {message: 'Required'}),
    role: z.object({
        uuid: z.string(),
        userRole: z.enum([
            'ORGANIZATION_PRODUCT_MANAGER',
            'ORGANIZATION_ADMIN',
            'ADMIN',
        ]),
    }),
    password: z.string().trim().min(1, {message: 'Required'}),
})

export const userSchemaWithoutPassword = userSchema.omit({password: true})

export type UserSchema = z.infer<typeof userSchema>
export type UserSchemaWithoutPassword = Omit<UserSchema, 'password'>

export type User = {
    uuid: string
    email: string
    firstname: string
    lastname: string
    organizationId: string
    role: Role
    status: string
    password?: string
}

const UserApi = {
    create: async (
        params: UserSchema & {
            organizationId: string
            password: string
            status: string
        },
    ): Promise<User> => {
        params.email = params.email.toLowerCase()
        const config = {
            url: '/users',
            method: 'POST',
            data: params,
        }

        try {
            const {data} = await instance.request(config)
            return data
        } catch (error: any) {
            throw new Error(`Failed to create a user: ${error.message}`)
        }
    },

    get: async (params: { userId: string }): Promise<User> => {
        const {userId} = params

        const config = {
            url: `/users/${userId}`,
            method: 'GET',
        }

        try {
            const {data} = await instance.request(config)

            return data
        } catch (error: any) {
            throw new Error(`Failed to fetch user: ${error.message}`)
        }
    },

    getAllByCompany: async (params: {
        companyId: string
        perPage?: number
        page?: number
    }): Promise<{
        users: User[]
        pagingInfo: PageInfo
    }> => {
        const {companyId, ...rest} = params
        const config = {
            url: `/users/organizations/${companyId}`,
            method: 'GET',
            params: {
                ...rest,
            },
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(
                `Failed to fetch all users of organization: ${error.message}`,
            )
        }
    },

    update: async (
        params: {
            userId: string
        } & UserSchemaWithoutPassword,
    ): Promise<User> => {
        const {userId, ...rest} = params

        const config = {
            url: `/users/${userId}`,
            method: 'PUT',
            data: {
                ...rest,
            },
        }

        try {
            const {data} = await instance.request(config)

            return data
        } catch (error: any) {
            throw new Error(`Failed to update user: ${error.message}`)
        }
    },
}

export default UserApi
