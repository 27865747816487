import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/solid'

import Dropdown from 'src/components/parts/Dropdown'
import { PageInfo } from 'src/services/api/portfolioApi'

export type PaginatorProps = {
    pageInfo: PageInfo
    handleSetPerPage: (value: number) => void
    handleSetCurrentPage: (value: number) => void
}

const perPageOptions = [
    { id: 1, label: '5', value: 5 },
    { id: 2, label: '10', value: 10 },
    { id: 3, label: '20', value: 20 },
    { id: 4, label: '50', value: 50 },
    { id: 5, label: '100', value: 100 },
]

const Paginator = ({
    pageInfo,
    handleSetPerPage,
    handleSetCurrentPage,
}: PaginatorProps) => {
    const { currentPage, perPage, totalCount, pageCount } = pageInfo

    const rangeMin =
        pageInfo && (pageInfo.currentPage - 1) * pageInfo.perPage + 1
    const rangeMax =
        pageInfo &&
        Math.min(pageInfo.currentPage * pageInfo.perPage, pageInfo.totalCount)
    const range = `${rangeMin} - ${rangeMax}`

    return (
        <>
            {pageCount > 1 && (
                <div className='flex w-full justify-between border border-neutral-300 bg-neutral-200 text-neutral-500'>
                    <div className='flex items-center justify-between w-full py-3 px-5 text-sm'>
                        <div className='font-medium'>
                            {range} of {totalCount}
                        </div>
                        <div className='flex items-center gap-5'>
                            <div className='flex items-center gap-1'>
                                <span className='font-medium text-nowrap'>
                                    Rows per page:
                                </span>
                                <Dropdown
                                    options={perPageOptions}
                                    handleSelectedOption={handleSetPerPage}
                                    className='bg-neutral-200'
                                >
                                    <div className='flex gap-2 text-neutral-500'>
                                        <div className='flex items-center gap-1'>
                                            <span className='font-semibold'>
                                                {perPage}
                                            </span>
                                            <ChevronDownIcon className='h-4 w-4 text-neutral-700' />
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                            <div className='flex item-center gap-2'>
                                <button
                                    onClick={() =>
                                        handleSetCurrentPage(currentPage - 1)
                                    }
                                    type='button'
                                    className={`px-1 py-0.5 rounded-md border-[1px] ${currentPage <= 1 ? 'border-neutral-200' : 'border-neutral-400 bg-white'}`}
                                    disabled={currentPage <= 1}
                                >
                                    <ChevronLeftIcon className='h-4 w-4 text-neutral-700' />
                                </button>
                                <span className='flex justify-center items-center font-semibold text-primary-dark gap-1'>
                                    <span>{currentPage}</span>
                                    <span className='font-normal'>
                                        / {pageCount}
                                    </span>
                                </span>
                                <button
                                    onClick={() =>
                                        handleSetCurrentPage(currentPage + 1)
                                    }
                                    type='button'
                                    className={`px-1 py-0.5 rounded-md border-[1px] ${currentPage >= pageCount ? 'border-neutral-200' : 'border-neutral-400 bg-white'} `}
                                    disabled={currentPage >= pageCount}
                                >
                                    <ChevronRightIcon className='h-4 w-4 text-neutral-700' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Paginator
