import toast from 'react-hot-toast'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'

import AuthApi from 'src/services/api/authApi'
import CompanyApi from 'src/services/api/companyApi'
import useCookies from 'src/services/hooks/useCookie'
import { useAuthContext } from 'src/context/AuthProvider'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'

const PersistLogin = () => {
    const cookies = useCookies()
    const { auth, setAuth, setCompany } = useAuthContext()

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const authToken = cookies.get('token')

    useEffect(() => {
        const resetAuth = async () => {
            try {
                if (!authToken) return

                const authUser = await AuthApi.me()

                if (authUser.role.userRole !== 'ADMIN') {
                    const companyData = await CompanyApi.get({
                        companyId: authUser.organizationId,
                    })

                    setCompany(companyData)
                }

                setAuth({ token: authToken, user: authUser })
                setIsLoading(false)
            } catch (error: any) {
                toast.error('Something went wrong. Please login.')
            } finally {
                setIsLoading(false)
            }
        }

        !auth?.token ? resetAuth() : setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoading ?
                <div className='fixed inset-0 flex items-center justify-center'>
                    <LoadingSpinner />
                </div>
            :   <Outlet />}
        </>
    )
}

export default PersistLogin
