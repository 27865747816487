import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'

import PortfolioApi from 'src/services/api/portfolioApi'
import {useAuthContext} from 'src/context/AuthProvider'
import ToastWidget from 'src/components/parts/ToastWidget'
import {useProdDeploymentsQuery} from 'src/services/hooks/useProdDeploymentsQuery'

export const useHooks = () => {
    const queryClient = useQueryClient()
    const {company} = useAuthContext()
    const navigate = useNavigate()

    const companyId = company?.uuid
    const isSupplierCompany = company?.type === 'SUPPLIER'

    const {prodDeployments, isLoadingProdDeployments} =
        useProdDeploymentsQuery({
            companyId,
            enabled: !isSupplierCompany,
        })

    const {cancelDeployment} = PortfolioApi

    const handleDeploymentError = (deploymentId: string | number, environment: string = "production") => {
        navigate(`/deployment/${environment}/${deploymentId}`)
    }

    const handleCancelDeployment = async (deploymentId: string) => {
        if (!companyId) return

        try {
            await cancelDeployment({companyId, deploymentId})

            toast.success('Deployment canceled')
            queryClient.invalidateQueries('prodDeployments')
        } catch (error: any) {
            toast.error(
                (t) => (
                    <ToastWidget
                        label='Cancel Production Deployment'
                        message={error.message}
                        handleClose={() => toast.dismiss(t.id)}
                    />
                ),
                {position: 'bottom-right'},
            )
        }
    }

    return {
        prodDeployments,
        isLoadingProdDeployments,
        handleDeploymentError,
        handleCancelDeployment,
    }
}
