// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'add-image': 'add image',
    'add-new-company': 'Add New Company',
    'add-user': 'Add User',
    all: 'All',
    cancel: 'Cancel',
    category: 'category',
    company: 'Company',
    'confirm-add-new-company':
        'Are you sure you want to add this new company to the list?',
    'creating-new-company': 'Creating a new company',
    'confirm-adding-new-user-to':
        'Are you sure you want to add this new user to',
    details: 'View',
    distributor: 'Distributor',
    distributors: 'Distributors',
    'edit-details': 'Edit details',
    'email-address': 'email address',
    'enter-email-address': 'Enter email address',
    'enter-first-name': 'Enter first name',
    'enter-last-name': 'Enter last name',
    'enter-name': 'Enter name',
    'enter-production-instance-url': 'Enter production instance URL',
    'first-name': 'First name',
    'go-back': 'Go back',
    'intializing-portfolio-data':
        'Initializing your portfolio data. This process should only take a moment.',
    'last-updated': 'last updated',
    'last-name': 'Last name',
    'main-language': 'main language',
    name: 'name',
    'other-languages': 'other languages',
    password: 'Password',
    profile: 'Profile',
    'paste-production-instance-url': 'Enter production instance url',
    'production-instance-url': 'production instance url',
    role: 'role',
    'save-changes': 'Save Changes',
    'select-role': 'Select role',
    save: 'Save',
    status: 'status',
    supplier: 'Supplier',
    suppliers: 'Suppliers',
    users: 'users',
    'user-info': 'User info',
    'successfully-updated-user': 'Successfully updated user',
    'initializing-portfolio-data': "Initializing database",
    "edit-user": "Edit",
}
