import {useState} from 'react'
import toast from 'react-hot-toast'
import {QueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'

import {useCompanyQuery} from 'src/services/hooks/useCompanyQuery'
import CompanyApi, {CompanySchema, companySchema,} from 'src/services/api/companyApi'

const companyImage = null
const categories = [
    {id: 1, label: 'distributor', value: 'DISTRIBUTOR'},
    {id: 2, label: 'supplier', value: 'SUPPLIER'},
]

export const useHooks = () => {
    const queryClient = new QueryClient()
    const navigate = useNavigate()
    const {createCompany} = useCompanyQuery({})

    const [isLoadingCreateCompany, setIsLoadingCreateCompany] = useState(false)
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const {get} = CompanyApi

    const {
        register,
        unregister,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<CompanySchema>({
        resolver: zodResolver(companySchema),
    })

    const {type} = useWatch({control})

    const handleBack = () => {
        navigate('/company')
    }

    const handleAddCompany = () => {
        setIsShowModal(true)
    }

    const onSubmit = async (data: CompanySchema) => {
        const {productionInstanceUrl, ...rest} = data

        try {
            setIsLoadingCreateCompany(true)
            let res = null

            if (type === 'DISTRIBUTOR') {
                res = await createCompany(data)
            } else {
                res = await createCompany(rest)
            }

            fetchNewCompany(res.uuid)
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setIsShowModal(false)
        }
    }

    const fetchNewCompany = async (companyId: string) => {
        try {
            await get({companyId})
            toast.success('Successfully created a new company!')
            queryClient.invalidateQueries('companies')

            setIsLoadingCreateCompany(false)
            navigate('/company')
        } catch (error) {
            setTimeout(() => fetchNewCompany(companyId), 2000)
        }
    }

    const handleCancel = () => {
        setIsShowModal(false)
    }

    return {
        type,
        errors,
        categories,
        isShowModal,
        companyImage,
        isLoadingCreateCompany,
        onSubmit,
        register,
        unregister,
        handleBack,
        handleSubmit,
        handleCancel,
        handleAddCompany,
    }
}
