import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { toTitleCase } from 'src/utils/toTitleCase'
import { useAuthContext } from 'src/context/AuthProvider'

type LinkType = { id: number; label: string; value: string }

const links = [
    { id: 1, label: 'portfolio', value: 'portfolio' },
    { id: 2, label: 'company', value: 'company' },
    { id: 3, label: 'deployment', value: 'deployment/test-instance' },
]

const Navbar = () => {
    const { auth } = useAuthContext()

    const isAdmin = auth?.user?.role?.userRole === 'ADMIN'
    const isOrgAdmin = auth?.user?.role?.userRole === 'ORGANIZATION_ADMIN'
    const isProductManager =
        auth?.user?.role?.userRole === 'ORGANIZATION_PRODUCT_MANAGER'

    let filteredLinks: LinkType[] = []

    const filterLinks = (filters: string[]) => {
        return links.filter(({ label }) => filters.includes(label))
    }

    switch (true) {
        case isAdmin:
            filteredLinks = filterLinks(['company'])
            break
        case isOrgAdmin:
            filteredLinks = filterLinks(['portfolio', 'company', 'deployment'])
            break
        case isProductManager:
            filteredLinks = filterLinks(['portfolio', 'deployment'])
            break
    }

    const { pathname } = useLocation()
    const [activeLink, setActiveLink] = useState<string>('/')

    useEffect(() => {
        setActiveLink(pathname.split('/')[1])
    }, [pathname])

    return (
        <div className='flex gap-10'>
            {filteredLinks.map(({ id, label, value }) => {
                return (
                    <div key={id} className='flex gap-10'>
                        <div key={id} className='flex'>
                            <Link
                                to={`/${value}`}
                                className={`flex items-center h-10 text-base font-semibold text-neutral-400 hover:text-neutral-700 ${activeLink === label && 'text-primary-dark'}`}
                            >
                                {toTitleCase(label)}
                            </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Navbar
