import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/solid'

import { toTitleCase } from 'src/utils/toTitleCase'
import { useAuthContext } from 'src/context/AuthProvider'
import { useAuthApi } from 'src/services/hooks/useAuthApi'

const ProfileDropdown = () => {
    const { auth } = useAuthContext()
    const { logout } = useAuthApi()
    const [isShow, setIsShow] = useState<boolean>(false)
    const modalRef = useRef<HTMLDivElement>(null)

    const isAdmin = auth?.user?.role.userRole === 'ADMIN'

    const handleShowOptions = () => {
        setIsShow(!isShow)
    }

    const handleLogout = () => {
        logout()
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setIsShow(false)
        }
    }

    useEffect(() => {
        isShow ?
            document.addEventListener('mousedown', handleClickOutside)
        :   document.removeEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isShow])

    return (
        <div
            onClick={handleShowOptions}
            className='relative flex items-center gap-5 px-[10px] py-2 rounded-md cursor-pointer z-50'
            ref={modalRef}
        >
            <UserCircleIcon className='h-8 w-8 text-neutral-700' />
            {isShow && (
                <div className='absolute flex flex-col gap-1 p-2 w-full min-w-fit top-0 right-0 translate-y-1/2 border-[1px] border-neutral-200 bg-white rounded-md'>
                    <Link
                        to={isAdmin ? 'company' : 'profile/details'}
                        className='flex items-center justify-center h-10 w-full min-w-fit px-4 rounded-md hover:bg-neutral-100 cursor-pointer whitespace-nowrap'
                    >
                        <span>
                            {isAdmin ?
                                'Admin'
                            :   toTitleCase(
                                    `${auth?.user?.firstname} ${auth?.user?.lastname}`,
                                )
                            }
                        </span>
                    </Link>
                    <button
                        onClick={handleLogout}
                        className='flex items-center justify-center h-10 w-full min-w-fit px-4 rounded-md hover:bg-neutral-100 cursor-pointer'
                    >
                        {t('logout', { ns: 'auth' })}
                    </button>
                </div>
            )}
        </div>
    )
}
export default ProfileDropdown
