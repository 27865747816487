// eslint-disable-next-line import/no-anonymous-default-export
export default {
    '1-non-letter-character': '1 non-letter character',
    'a-lowercase-character': 'A lowercase character',
    'an-uppercase-character': 'An uppercase character',
    confirm: 'Confirm',
    'confirm-password': 'Confirm password',
    continue: 'Continue',
    'email-address': 'Email address',
    'enter-email-address': 'Enter email address',
    'enter-new-password': 'Enter new password',
    'enter-password': 'Enter password',
    'enter-old-password': 'Enter old password',
    'enter-password-confirmation': 'Enter password confirmation',
    'forgot-password': 'Forgot password?',
    'include-at-least-two-of-the-following':
        'Include at least two of the following',
    'log-in': 'Login',
    'login-failed': 'Login failed',
    'login-page': 'Login Page',
    logout: 'Logout',
    'minimum-8-characters': 'Minimum of 8 characters',
    'new-password': 'New password',
    'new-password-matches-the-old-password':
        'New password matches the old password',
    password: 'Password',
    'old-password': 'Old Password',
    'password-must-be': 'Password must be',
    'please-enter-your-details-to-login': 'Please enter your details to login',
    'remember-me': 'Remember me',
    'set-new-password': 'Set new password',
    'welcome-back-to-mms': 'Welcome back to MMS',
    'password-sent-to-email':
        ' We have sent you an email containing your new password',
    'reset-password': 'Reset password',
    'go-back-to': 'Go back to',
    'enter-email-to-receive-password':
        'Enter the email address associated with your account and we will send you instructions on how to reset your password.',
    success: 'Success',
    'password-reset-successful': 'Your password has been set successfully.',
}
