import instance from './axiosInstance'
import {PageInfo} from './portfolioApi'

export type ReferenceData = {
    uuid: string
    '@class': 'string'
    Artikelnummer: string
    Gesperrt: boolean
    Lieferbar: boolean
    Name: string
    Repraesentant: boolean
    en: string
    de?: string
    extendInfo: string
}

export type ReferenceDataVersion = {
    version: string
    publishDate: string
}

const ReferenceDataApi = {
    upload: async (params: { file: File; companyId: string }) => {
        const {file, companyId} = params

        const formData = new FormData()
        formData.append('file', file)

        const config = {
            url: `/organizations/${companyId}/referencedata/upload`,
            method: 'POST',
            data: formData,
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                if (error.response.data.validationMessages) {
                    return error.response.data
                } else {
                    throw new Error(error.response.data.message)
                }
            } else {
                throw new Error(
                    `Failed to upload reference data: ${error.message}`,
                )
            }
        }
    },

    publish: async ({companyId}: { companyId: string }) => {
        const config = {
            url: `/organizations/${companyId}/referencedata/publish`,
            method: 'POST',
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            let err = error.response?.data?.message;
            if (!err) {
                err = error.message;
            }
            throw new Error(
                err,
            )
        }
    },

    getAllVersions: async ({
                               companyId,
                               perPage,
                               page
                           }: {
        companyId: string
        perPage?: number
        page?: number
    }): Promise<{
        dataVersionList: ReferenceDataVersion[]
        pagingInfo: PageInfo
    }> => {
        const config = {
            url: `/organizations/${companyId}/referencedata/list/versions`,
            method: 'GET',
            params: {
                perPage,
                page
            }
        }

        try {
            const res = await instance.request(config)
            return res.data
        } catch (error: any) {
            throw new Error(
                `Failed to fetch reference data versions: ${error.message}`,
            )
        }
    },
}

export default ReferenceDataApi
