import { useState, ReactNode, useEffect, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

// Global state to keep track of open dropdowns
const openDropdowns = new Set<string>()

// Function to close all open dropdowns
const closeAllDropdowns = () => {
  openDropdowns.forEach((dropdownId) => {
    const event = new CustomEvent('closeDropdown', { detail: { id: dropdownId } })
    window.dispatchEvent(event)
  })
  openDropdowns.clear()
}

// Custom hook for managing dropdown state
const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [id] = useState(() => uuidv4())

  const open = useCallback(() => {
    openDropdowns.forEach((dropdownId) => {
      if (dropdownId !== id) {
        const event = new CustomEvent('closeDropdown', { detail: { id: dropdownId } })
        window.dispatchEvent(event)
      }
    })
    openDropdowns.add(id)
    setIsOpen(true)
  }, [id])

  const close = useCallback(() => {
    openDropdowns.delete(id)
    setIsOpen(false)
  }, [id])

  useEffect(() => {
    const handleCloseDropdown = (event: CustomEvent) => {
      if (event.detail.id === id) {
        close()
      }
    }

    window.addEventListener('closeDropdown', handleCloseDropdown as EventListener)

    return () => {
      window.removeEventListener('closeDropdown', handleCloseDropdown as EventListener)
    }
  }, [id, close])

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      if (!(event.target as Element).closest('.dropdown-container')) {
        closeAllDropdowns()
      }
    }

    document.addEventListener('click', handleGlobalClick)

    return () => {
      document.removeEventListener('click', handleGlobalClick)
    }
  }, [])

  return { isOpen, open, close }
}

type DropdownOption = {
    id: number | string
    label: string
    value: string | boolean | number
    title?: string // Optional hover text
}

type DropdownProps = {
    options: DropdownOption[]
    children: ReactNode
    disabled?: boolean
    className?: string
    handleSelectedOption: (value: any) => void
    hoverText?: string // Add hoverText prop
}

const Dropdown = ({
    options,
    handleSelectedOption,
    children,
    className,
    disabled,
    hoverText,
}: DropdownProps) => {
    const { isOpen, open, close } = useDropdown()
    const [isHovered, setIsHovered] = useState<boolean>(false)

    const handleShowDropdown = () => {
        if (isOpen) {
            close()
        } else {
            open()
        }
    }

    const handleOptionSelect = (option: string | boolean | number) => {
        handleSelectedOption(option)
        close()
    }

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                handleShowDropdown()
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`dropdown-container relative flex items-center gap-2 w-full px-[10px] py-2 rounded-lg cursor-pointer text-primary-dark ${className}`}
            title={options.find(option => option.value === children)?.title || ''}
        >
            {isHovered && hoverText && (
                <div className="absolute top-0 left-0 bg-gray-800 text-white p-2 rounded-md text-sm">
                    {hoverText}
                </div>
            )}
            {children}
            {isOpen && (
                <div className='absolute flex flex-col p-2 gap-1 w-full -bottom-2 translate-y-full left-0 border-[1px] border-neutral-200 bg-white rounded-md z-20'>
                    {options &&
                        options.map(({ id, label, value, title }) => {
                            return (
                                <button
                                    key={id}
                                    className='flex items-center justify-center h-10 w-full rounded-md text-primary-dark hover:bg-neutral-100 cursor-pointer'
                                    onClick={() => handleOptionSelect(value)}
                                    disabled={disabled}
                                    title={title || label} // Use title if provided, otherwise use label
                                >
                                    {label}
                                </button>
                            )
                        })}
                </div>
            )}
        </div>
    )
}

export default Dropdown