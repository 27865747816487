// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'add-image': 'Bild hinzufügen',
    'add-new-company': 'Neue Firma hinzufügen',
    'add-user': 'Benutzer hinzufügen',
    all: 'Alle',
    cancel: 'Abbrechen',
    category: 'Kategorie',
    company: 'Firma',
    'confirm-add-new-company':
        'Sind Sie sicher, dass Sie diese neue Firma zur Liste hinzufügen möchten?',
    'creating-new-company': 'Neue Firma wird erstellt',
    'confirm-adding-new-user-to':
        'Sind Sie sicher, dass Sie diesen neuen Benutzer hinzufügen möchten zu',
    details: 'Details',
    distributor: 'Vertriebsunternehmen',
    distributors: 'Vertriebsunternehmen',
    'edit-details': 'Details bearbeiten',
    'email-address': 'E-Mail-Adresse',
    'enter-email-address': 'E-Mail-Adresse eingeben',
    'enter-first-name': 'Vorname eingeben',
    'enter-last-name': 'Nachname eingeben',
    'enter-name': 'Name eingeben',
    'enter-production-instance-url': 'Produktions-URL eingeben',
    'first-name': 'Vorname',
    'go-back': 'Zurück gehen',
    'intializing-portfolio-data':
        'Ihre Portfoliodaten werden initialisiert. Dieser Vorgang sollte nur einen Moment dauern.',
    'last-updated': 'Zuletzt aktualisiert',
    'last-name': 'Nachname',
    'main-language': 'Hauptsprache',
    name: 'Name',
    'other-languages': 'Andere Sprachen',
    password: 'Passwort',
    profile: 'Profil',
    'paste-production-instance-url': 'https://www.solar-planit.de/',
    'production-instance-url': 'Produktions-URL',
    role: 'Rolle',
    'save-changes': 'Änderungen speichern',
    'select-role': 'Rolle auswählen',
    save: 'Speichern',
    status: 'Status',
    supplier: 'Lieferant',
    suppliers: 'Lieferanten',
    users: 'Benutzer',
    'user-info': 'Benutzerinformationen',
    'successfully-updated-user': 'Benutzer erfolgreich aktualisiert',
    'initializing-portfolio-data': "Initialisiere Datenbank",
    "edit-user": "Bearbeiten",
}
