import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { useAuthContext } from 'src/context/AuthProvider'
import { usePortfolioItemQuery } from 'src/services/hooks/usePortfolioItemQuery'

export const useHooks = () => {
    const { portfolioItemId } = useParams()
    const { company } = useAuthContext()

    const { portfolioItem, updatePortfolioItem, isLoadingPortfolioItem } =
        usePortfolioItemQuery({
            companyId: company?.uuid,
            portfolioItemId,
        })

    const handleSetStatus = async (value: boolean) => {
        if (portfolioItemId && company) {
            try {
                await updatePortfolioItem({
                    companyId: company?.uuid,
                    portfolioItemId,
                    data: {
                        active: value,
                    },
                })
            } catch (error: any) {
                toast.error(error.message)
            }
        }
    }

    const handleSetRepresentant = async () => {
        if (portfolioItemId && portfolioItem && company) {
            try {
                await updatePortfolioItem({
                    companyId: company?.uuid,
                    portfolioItemId,
                    data: {
                        Repraesentant: !portfolioItem.Repraesentant,
                    },
                })
            } catch (error: any) {
                toast.error(error.message)
            }
        }
    }

    return {
        portfolioItem,
        isLoadingPortfolioItem,
        handleSetStatus,
        handleSetRepresentant,
    }
}
