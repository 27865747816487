import { t } from 'i18next'
import { Link } from 'react-router-dom'

import Pill from 'src/components/parts/Pill'
import { toTitleCase } from 'src/utils/toTitleCase'
import BackButton from 'src/components/parts/BackButton'
import LoadingSpinner from 'src/components/parts/LoadingSpinner'
import NoDetailsToShow from 'src/components/parts/NoDetailsToShow'
import CompanyDetails from 'src/components/templates/CompanyDetails'

import CompanyUsers from './users'
import { useHooks } from './hooks'

type Tabs = {
    id: number
    label: string
    value: 'details' | 'users'
}[]

const tabs: Tabs = [
    { id: 1, label: 'details', value: 'details' },
    { id: 2, label: 'users', value: 'users' },
]

const CompanyPage = () => {
    const { page, isAdmin, company, isLoadingCompany } = useHooks()

    if (isLoadingCompany) {
        return <LoadingSpinner />
    }

    return (
        <div className='flex flex-col w-full gap-4 text-primary-dark'>
            {company ?
                <>
                    {isAdmin && (
                        <div className='flex items-center gap-4'>
                            <BackButton href='/company' />
                            <span className='font-bold text-2xl'>
                                {company?.name}
                            </span>
                        </div>
                    )}
                    <div
                        className={`relative flex  flex-col gap-4 ${isAdmin && 'px-10'}`}
                    >
                        <div className='flex gap-6'>
                            {tabs.map(({ label, value }, index) => {
                                return (
                                    <Link
                                        to={`/company/${company.uuid}/${value}`}
                                        key={index}
                                        className={`flex gap-2 h-9 text-sm font-bold text-neutral-700 cursor-pointer 
                                ${page === value && 'text-primary-dark border-b-2 border-primary-dark'}
                                `}
                                    >
                                        {toTitleCase(
                                            t(`${label}`, { ns: 'company' }),
                                        )}
                                    </Link>
                                )
                            })}
                        </div>
                        {page === 'details' ?
                            <CompanyDetails company={company} />
                        :   <CompanyUsers />}
                    </div>
                </>
            :   <>
                    <div className='flex items-center gap-4'>
                        <BackButton href='/company' />
                        <span className='font-bold'>
                            {t('go-back', { ns: 'company' })}
                        </span>
                    </div>
                    <NoDetailsToShow />
                </>
            }
        </div>
    )
}
export default CompanyPage
