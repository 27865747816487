import { Outlet } from 'react-router-dom'

import Header from 'src/components/templates/Header'

const Layout = () => {
    return (
        <div className='min-h-screen flex flex-col w-full bg-surface'>
            <Header />
            <div className='p-10'>
                <Outlet />
            </div>
        </div>
    )
}

export default Layout
