import Logo from "../../components/parts/Logo";

const Error = () => {
    return (
        <div className='flex h-screen'>
            <Logo className='absolute top-4 left-4'/>
            <div className='flex justify-center items-center w-1/2 min-w-96 h-full '>
                <div className='flex flex-col min-w-80 max-w-96 gap-8'>
                    <div className='flex flex-col items-center space-y-[10px] text-center'>
                        <h1 className='text-2xl font-bold text-primary-dark '>
                            This site is currently under maintenance. <br/>
                            Please check again later.
                        </h1>
                    </div>
                </div>
            </div>
            <div className='flex w-1/2 h-full bg-primary-green'/>
        </div>
    )
}
export default Error
