// eslint-disable-next-line import/no-anonymous-default-export
export default {
    company: 'Firma',
    cancel: 'Abbrechen',
    'change-password': 'passwort ändern',
    deployment: 'Bereitstellung',
    details: 'Details',
    edit: 'Bearbeiten',
    english: 'Englisch',
    'email-address': 'E-Mail-Adresse',
    german: 'Deutsch',
    name: 'Name',
    no: 'Nein',
    'please-enter-your-details-to-login':
        'Bitte geben Sie Ihre Daten ein, um sich anzumelden',
    portfolio: 'Portfolio',
    'page-not-found': 'Seite nicht gefunden',
    'passwords-do-not-match': 'Passwörter stimmen nicht überein',
    unauthorized: 'Unbefugt',
    'unauthorized-message':
        'Sie haben nicht die erforderlichen Berechtigungen, um diese Seite anzuzeigen oder auf diese Ressource zuzugreifen. Bitte wenden Sie sich an Ihren Administrator, um Unterstützung zu erhalten.',
    settings: 'Einstellungen',
    'welcome-to-mms': 'Willkommen zurück bei MMS',
    save: 'Speichern',
    yes: 'Ja',
    'no-details-to-show': 'Keine Daten vorhanden',
}
